import api from 'services/api'

export const get = async () => {
    const response = await api.get('/chapters')

    return response
}

export default {
    get
}
