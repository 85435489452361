import ActionButton from '../Buttons/Action'
import BackButton from '../Buttons/Back'

export default function Header({
    className,
    onBackward,
    onAction,
    title,
    description,
    showAction = false,
    actionIcon,
    actionClass
}) {
    return (
        <div className={`flex items-center ${className}`}>
            <BackButton onClick={onBackward} />
            <div className="ml-6 mr-auto">
                <div className="text-white text-lg font-bold ">{title}</div>
                {description && <div className="text-sm">{description}</div>}
            </div>
            {showAction && <ActionButton onClick={onAction} className={`ml-6 ${actionClass}`} Icon={actionIcon} />}
        </div>
    )
}
