import React, { useState, createContext, useEffect } from 'react'
import { LOCAL_USER_STORAGE } from 'constants'

export const UserContext = createContext()

export const UserProvider = ({ children }) => {
    const initialState = {
        subscribed: false,
        token: '',
        user: {
            _id: '',
            email: '',
            name: '',
            lastName: '',
            role: '',
            chapter: ''
        }
    }

    const [state, setState] = useState(() => {
        const state = JSON.parse(localStorage.getItem(LOCAL_USER_STORAGE))

        if (state === null) {
            return initialState
        }

        return state
    })

    useEffect(() => {
        localStorage.setItem(LOCAL_USER_STORAGE, JSON.stringify(state))
    }, [state])

    const resetState = () => {
        localStorage.removeItem(LOCAL_USER_STORAGE)
        setState(initialState)
    }

    const isMyProfile = (memberID) => {
        return memberID === state.user._id
    }

    const providerValues = {
        state,
        setState,
        resetState,
        isMyProfile
    }

    return (
        <UserContext.Provider value={providerValues}>
            {children}
        </UserContext.Provider>
    )
}
