import api, { setBearerToken } from 'services/api'

export const signin = async ({ email, password }) => {
    const response = await api.post('/account/signin', {
        email,
        password
    })

    setBearerToken(response.data.token)

    return response
}

export const signup = async ({
    name,
    lastName,
    email,
    password,
    invitationToken
}) => {
    const response = await api.post('/account/signup', {
        name,
        lastName,
        email,
        password,
        invitationToken
    })

    setBearerToken(response.data.token)

    return response
}

export const invitation = async ({ role, chapter }) => {
    const response = await api.post('/account/invitation', {
        role,
        chapter
    })

    return response
}

export const validateToken = async ({ uid }) => {
    const response = await api.post('/account/validate-token', {
        uid
    })

    return response
}

export default {
    signin,
    signup,
    invitation,
    validateToken
}
