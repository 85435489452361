import React from 'react'
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { KilometersAPI } from 'api'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useState } from 'react'

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement
)

export default function AnnualKilometers({ kilometers = [] }) {
    const { memberID } = useParams()
    const [kilometersList, setKilometersList] = useState([])
    const [kilometersDate, setKilometersDate] = useState([])

    useEffect(() => {
        onGetKilometers()
    }, [])

    const onGetKilometers = async () => {
        const {
            data: { kilometers }
        } = await KilometersAPI.get(memberID, {
            filter: 'year'
        })

        const list = []
        const dates = []

        kilometers.forEach((element) => {
            list.unshift(element.total)
            dates.unshift(element.date)
        })

        setKilometersList(list)
        setKilometersDate(dates)
    }

    const data = {
        labels: kilometersDate,
        datasets: [
            {
                tension: 0.4,
                data: kilometersList,
                backgroundColor: ['#F4874C'],
                borderColor: ['#F4874C'],
                borderWidth: 2.5
            }
        ]
    }

    let options = {
        plugins: {
            legend: {
                display: false
            }
        },
        scales: {
            x: {
                display: false
            },
            y: {
                display: false
            }
        },
        elements: {
            point: {
                radius: 0
            }
        }
    }

    return <Line options={options} data={data} />
}
