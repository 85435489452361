import React, { useEffect } from 'react'
import { LOCAL_USER_STORAGE } from 'constants'
import { setBearerToken } from 'services/api'
import dayjs from 'dayjs'
import es from 'dayjs/locale/es'

dayjs.locale(es)

function App() {
    useEffect(() => {
        window.addEventListener('resize', setRootWidth)
        setRootWidth()
        setAuthorizationToken()
    }, [])

    const setAuthorizationToken = () => {
        const token = JSON.parse(
            localStorage.getItem(LOCAL_USER_STORAGE)
        )?.token

        setBearerToken(token)
    }

    const setRootWidth = () => {
        const root = document.getElementById('root')

        if (window.innerWidth > 480) {
            root.classList.add('desktop-wrapper')
        } else {
            root.classList.remove('desktop-wrapper')
        }
    }

    return <div className="app"></div>
}

export default App
