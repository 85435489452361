import { commaNumber } from 'utils'

export default function MemberItem({ data, onClick, isMe }) {
    return (
        <div
            className={`flex justify-between items-center gap-4 p-4 rounded-lg ${
                isMe ? 'bg-dark-400' : 'bg-dark-500'
            }`}
            onClick={() => onClick(data)}>
            <div className="overflow-x-hidden">
                <div className="text-white text-sm capitalize whitespace-nowrap overflow-x-hidden text-ellipsis">
                    {data.name + ' ' + data.lastName} {isMe ? '(Tú)' : ''}
                </div>
                <div className="text-text text-xs capitalize whitespace-nowrap overflow-x-hidden text-ellipsis">
                    {data.role}
                </div>
            </div>

            <div className="text-secundary font-bold shrink-0">
                {commaNumber(data.totalKilometers)}{' '}
                <span className="text-xs">kms</span>
            </div>
        </div>
    )
}
