import React, { Fragment, useContext, useEffect, useState } from 'react'
import { LeadershipAPI } from 'api'
import { useNavigate } from 'react-router-dom'
import Header from 'components/Header'

import LeadershipItem from 'components/MemberItem/Leadership'
import SkeletonItem from 'components/SkeletonItem'
import dayjs from 'dayjs'
import { UserContext } from 'context/UserContext'

export default function Leadership() {
    const navigate = useNavigate()
    const { state: userState } = useContext(UserContext)
    const [members, setMembers] = useState([])
    const [loading, setLoading] = useState(true)
    const [currentYear] = useState(dayjs().format('YYYY'))

    useEffect(() => {
        getLeadershipMembers()
    }, [])

    const getLeadershipMembers = async () => {
        const { data } = await LeadershipAPI.get()

        if (data.success) {
            setMembers(data.users)
            return setLoading(false)
        }
    }

    const onBackward = () => {
        navigate('/home')
    }

    const onMemberDetails = (member) => {
        navigate(`/members/profile/${member._id}`)
    }

    return (
        <Fragment>
            <Header
                className="mb-12"
                onBackward={onBackward}
                title="Tablero de Líderes"
                description={`Miembros que más ruedan (${currentYear})`}
            />

            <div className="flex flex-col gap-6">
                {loading ? (
                    [...Array(10).keys()].map((index) => (
                        <SkeletonItem key={index} />
                    ))
                ) : (
                    <Fragment>
                        {members.map((item, index) => (
                            <LeadershipItem
                                position={index + 1}
                                key={item._id}
                                data={item}
                                isMe={item._id === userState.user._id}
                                onClick={onMemberDetails}
                            />
                        ))}
                    </Fragment>
                )}
            </div>
        </Fragment>
    )
}
