import React, { useContext, useEffect, useState, Fragment } from 'react'
import { MembersAPI, SubscriptionsAPI } from 'api'
import { useNavigate, useParams } from 'react-router-dom'
import Header from 'components/Header'
import { capitalizeText, commaNumber, requiredKilometers } from 'utils'
import dayjs from 'dayjs'
import {
    ChevronRightIcon,
    MapPinIcon,
    UserIcon,
    ArrowLeftOnRectangleIcon
} from '@heroicons/react/24/outline'
import { UserContext } from 'context/UserContext'
import CoyoteProfileSVG from 'assets/coyote.svg'
import SkeletonProfileItem from 'components/SkeletonItem/Profile'

export default function MembersDetails() {
    const navigate = useNavigate()
    const { _id: memberID } = useParams()
    const [member, setMember] = useState({})
    const [kilometers, setKilometers] = useState({})
    const [loading, setLoading] = useState(true)
    const [currentMonth] = useState(dayjs().format('MMMM'))
    const { resetState: userResetState, isMyProfile } = useContext(UserContext)
    const [isMe] = useState(isMyProfile(memberID))
    const [memberRequiredKilometers, setMemberRequiredKilometers] = useState(0)

    useEffect(() => {
        onGetMemberDetails()
    }, [])

    const onGetMemberDetails = async () => {
        const {
            data: { user: member, kilometers }
        } = await MembersAPI.getOne(memberID)

        setMember(member)
        setMemberRequiredKilometers(
            requiredKilometers(member.role, member.chapter)
        )
        setKilometers(kilometers)
        setLoading(false)
    }

    const onBackward = () => {
        navigate(-1)
    }

    const onLogout = () => {
        userResetState()
        SubscriptionsAPI.unsubscribe()
    }

    const getKilometersProgress = () => {
        let percent = (kilometers.annual / memberRequiredKilometers) * 100
        if (percent >= 100) return '100'
        return percent.toFixed(2)
    }

    return (
        <Fragment>
            <Header className="mb-12" onBackward={onBackward} />

            <div className="flex flex-col items-center text-center mb-8">
                <div className="w-28 h-28 bg-dark-400 rounded-full flex mb-4">
                    <img className="m-auto opacity-90" src={CoyoteProfileSVG} />
                </div>
                {loading ? (
                    <SkeletonProfileItem />
                ) : (
                    <Fragment>
                        <div className="text-white mb-1 text-xl font-semibold">
                            {capitalizeText(
                                member.name + ' ' + member.lastName,
                                true
                            )}{' '}
                            {isMe ? '(Tú)' : ''}
                        </div>
                        <div className="text-sm">
                            {capitalizeText(member.role)} del capitulo{' '}
                            {capitalizeText(member.chapter, true)}
                        </div>
                    </Fragment>
                )}
            </div>

            <div>
                <div className="flex justify-between items-center mb-2">
                    <div className="text-sm text-text">
                        Kilómetros requeridos
                    </div>
                    <div className="font-semibold text-secundary">
                        {commaNumber(kilometers.annual)} de{' '}
                        {commaNumber(memberRequiredKilometers)}{' '}
                        <span className="text-xs">kms</span>
                    </div>
                </div>
                <div className="h-2 bg-dark-500 rounded-2xl">
                    <div
                        className="h-2 w-0 bg-primary rounded-2xl"
                        style={{ width: getKilometersProgress() + '%' }}></div>
                </div>
            </div>

            <div className="flex justify-between mt-8 mb-16">
                <div className="flex flex-col text-center">
                    <span className="text-secundary font-semibold">
                        {commaNumber(kilometers.monthly)}
                    </span>
                    <span className="text-sm text-text">
                        Kilómetros de {currentMonth}
                    </span>
                </div>

                <div className="flex flex-col text-center">
                    <span className="text-secundary font-semibold">
                        {commaNumber(kilometers.annual)}
                    </span>
                    <span className="text-sm text-text">
                        Kilómetros del año
                    </span>
                </div>

                <div className="flex flex-col text-center">
                    <span className="text-secundary font-semibold">
                        {commaNumber(kilometers.total)}
                    </span>
                    <span className="text-sm text-text">
                        Total viajes del año
                    </span>
                </div>
            </div>

            <div className="flex flex-col gap-6">
                <div
                    className="flex items-center"
                    onClick={() => navigate(`/kilometers/${memberID}`)}>
                    <div className="w-10 h-10 bg-dark-500 rounded-full flex shrink-0">
                        <MapPinIcon className="w-5 m-auto" />
                    </div>
                    <div className="text-white font-semibold ml-4">
                        Kilómetros
                    </div>
                    <ChevronRightIcon className="w-6 ml-auto" />
                </div>
                <div
                    className="flex items-center"
                    onClick={() =>
                        navigate(`/members/profile/${memberID}/information`)
                    }>
                    <div className="w-10 h-10 bg-dark-500 rounded-full flex shrink-0">
                        <UserIcon className="w-5 m-auto" />
                    </div>
                    <div className="text-white font-semibold ml-4">
                        Información personal
                    </div>
                    <ChevronRightIcon className="w-6 ml-auto" />
                </div>
                {isMe && (
                    <div className="flex items-center" onClick={onLogout}>
                        <div className="w-10 h-10 bg-dark-500 rounded-full flex shrink-0">
                            <ArrowLeftOnRectangleIcon className="w-5 m-auto" />
                        </div>
                        <div className="text-white font-semibold ml-4">
                            Cerrar sesión
                        </div>
                        <ChevronRightIcon className="w-6 ml-auto" />
                    </div>
                )}
            </div>
        </Fragment>
    )
}
