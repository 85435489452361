import React, { Fragment, useState } from 'react'
import dayjs from 'dayjs'
import { commaNumber } from 'utils'

import SkeletonDashboardItem from 'components/SkeletonItem/Dashboard'
import AnnualKilometersChart from 'components/Charts/AnnualKilometers'

export default function HomeDetails({ kilometersDetails, loading }) {
    const [currentMonth] = useState(dayjs().format('MMMM'))

    return (
        <Fragment>
            {loading ? (
                <SkeletonDashboardItem />
            ) : (
                <div className="p-4 bg-dark-500 rounded-xl flex gap-4">
                    <div className="flex flex-col gap-4 w-1/2">
                        <div className="flex-initial">
                            <div className="text-xs flex">
                                Kilómetros por año
                            </div>
                            <div className="text-secundary font-bold flex items-end">
                                <div className="text-4xl">
                                    {commaNumber(kilometersDetails.annual)}
                                </div>
                                <span className="text-md ml-2">kms</span>
                            </div>
                        </div>
                        <div className="flex-initial">
                            <div className="text-xs flex">
                                Kilómetros mes de {currentMonth}
                            </div>
                            <div className="text-secundary font-bold flex items-end">
                                <div className="text-4xl">
                                    {commaNumber(kilometersDetails.monthly)}
                                </div>
                                <span className="text-md ml-2">kms</span>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-4 w-1/2">
                        <div className="flex-initial">
                            <div className="text-xs flex">
                                Total de viajes año
                            </div>
                            <div className="text-secundary text-4xl font-bold">
                                {commaNumber(kilometersDetails.total)}
                            </div>
                        </div>
                        <div className="flex-initial">
                            <div className="h-14 flex justify-center">
                                <AnnualKilometersChart />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    )
}
