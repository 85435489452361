import React, { useContext, useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import {
    EnvelopeIcon,
    LockClosedIcon,
    EyeIcon
} from '@heroicons/react/24/outline'
import { AccountAPI } from 'api'
import { UserContext } from 'context/UserContext'
import { useSearchParams } from 'react-router-dom'
import TokenValidationModal from 'components/Modals/TokenValidation'

export default function SignUp() {
    const formRef = useRef()
    const [searchParams] = useSearchParams()
    const [tokenPayload, setTokenPayload] = useState({})
    const [showTokenModal, setShowTokenModal] = useState(false)
    const { setState: setUserState } = useContext(UserContext)

    useEffect(() => {
        const uid = searchParams.get('uid')

        if (!uid) {
            setShowTokenModal(true)
        }

        validateToken(uid)
    }, [])

    const validateToken = async (uid) => {
        const { data } = await AccountAPI.validateToken({ uid })

        if (data.success) {
            return setTokenPayload(data.payload)
        }

        if (['TOKEN_DOESNT_EXIST', 'INVALID_TOKEN'].includes(data.message)) {
            setShowTokenModal(true)
        }
    }

    const onSignup = async ({ name, lastName, email, password }) => {
        const { data } = await AccountAPI.signup({
            name,
            lastName,
            email,
            password,
            invitationToken: tokenPayload.token
        })

        if (data.success) {
            const { user, token } = data

            return setUserState((prevState) => ({
                ...prevState,
                token,
                user
            }))
        }

        if (data.message === 'USER_ALREADY_EXISTS') {
            formRef.current.setFieldError(
                'email',
                'El correo electrónico ya existe'
            )
        }

        if (['TOKEN_DOESNT_EXIST', 'INVALID_TOKEN'].includes(data.message)) {
            formRef.current.setFieldError(
                'email',
                'El token no existe. Solicita un nuevo token'
            )
        }
    }

    const onShowPassword = ($event) => {
        const input = $event.currentTarget.nextSibling

        if (input.type === 'password') {
            input.type = 'text'
        } else {
            input.type = 'password'
        }
    }

    const initialValues = {
        name: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: ''
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Este campo es requerido'),
        lastName: Yup.string().required('Este campo es requerido'),
        email: Yup.string()
            .required('Este campo es requerido')
            .email('Correo electrónico inválido'),
        password: Yup.string()
            .required('Este campo es requerido')
            .matches(/^(|.{8,})$/, 'Debe tener al menos 8 caracteres'),
        confirmPassword: Yup.string()
            .required('Este campo es requerido')
            .oneOf(
                [Yup.ref('password'), null],
                'La contraseña no coincide con la de arriba'
            )
    })

    return (
        <div className="w-full min-h-full flex flex-col px-6 py-10">
            <TokenValidationModal
                isVisible={showTokenModal}
                showDetails={false}
            />

            <div className="mb-10">
                <h1 className="font-bold mb-2 text-2xl text-white">
                    Crear cuenta
                </h1>
                <p>
                    Un miembro del capítulo {tokenPayload.chapter} te envió una
                    invitación para que te unas como {tokenPayload.role}. Llena
                    la información y selecciona crear cuenta.
                </p>
            </div>

            <Formik
                initialValues={initialValues}
                onSubmit={onSignup}
                validationSchema={validationSchema}
                innerRef={formRef}>
                {({ errors, touched }) => (
                    <Form className="flex flex-col grow" autoComplete="off">
                        <div className="mb-auto flex flex-col gap-6">
                            <div className="flex gap-6">
                                <div className="w-1/2">
                                    <label
                                        htmlFor="name"
                                        className="inline-block text-sm mb-2 text-white">
                                        Nombre
                                    </label>
                                    <Field
                                        id="name"
                                        name="name"
                                        className={`w-full rounded-lg py-2 px-4 border ${
                                            errors.name && touched.name
                                                ? 'border-red focus:border-red bg-red/5 placeholder:text-red/50'
                                                : 'border-transparent focus:border-primary bg-dark-500 placeholder:text-text placeholder:text-sm'
                                        }`}
                                    />
                                    <ErrorMessage
                                        name="name"
                                        component="small"
                                        className="text-red inline-block text-xs w-full mt-2"
                                    />
                                </div>

                                <div className="w-1/2">
                                    <label
                                        htmlFor="lastName"
                                        className="inline-block text-sm mb-2 text-white">
                                        Apellido
                                    </label>
                                    <Field
                                        id="lastName"
                                        name="lastName"
                                        className={`w-full rounded-lg py-2 px-4 border ${
                                            errors.lastName && touched.lastName
                                                ? 'border-red focus:border-red bg-red/5 placeholder:text-red/50'
                                                : 'border-transparent focus:border-primary bg-dark-500 placeholder:text-text placeholder:text-sm'
                                        }`}
                                    />
                                    <ErrorMessage
                                        name="lastName"
                                        component="small"
                                        className="text-red inline-block text-xs w-full mt-2"
                                    />
                                </div>
                            </div>

                            <div className="h-0.5 bg-dark-400"></div>

                            <div>
                                <label
                                    htmlFor="email"
                                    className="inline-block text-sm mb-2 text-white">
                                    Correo electrónico
                                </label>
                                <div className="relative">
                                    <EnvelopeIcon
                                        className={`w-5 absolute top-1/2 -translate-y-1/2 left-4 ${
                                            errors.email && touched.email
                                                ? 'text-red'
                                                : 'text-text'
                                        }`}
                                    />
                                    <Field
                                        id="email"
                                        type="email"
                                        name="email"
                                        placeholder="Escribe tu nombre de usuario"
                                        className={`w-full rounded-lg py-2 px-4 border pl-12 ${
                                            errors.email && touched.email
                                                ? 'border-red focus:border-red bg-red/5 placeholder:text-red/50'
                                                : 'border-transparent focus:border-primary bg-dark-500 placeholder:text-text placeholder:text-sm'
                                        }`}
                                    />
                                </div>
                                <ErrorMessage
                                    name="email"
                                    component="small"
                                    className="text-red inline-block text-xs w-full mt-2"
                                />
                            </div>

                            <div>
                                <label
                                    htmlFor="password"
                                    className="inline-block text-sm mb-2 text-white">
                                    Contraseña
                                </label>
                                <div className="relative">
                                    <LockClosedIcon
                                        className={`w-5 absolute top-1/2 -translate-y-1/2 left-4 ${
                                            errors.password && touched.password
                                                ? 'text-red'
                                                : 'text-text'
                                        }`}
                                    />
                                    <EyeIcon
                                        className={`w-5 absolute top-1/2 -translate-y-1/2 right-4 ${
                                            errors.password && touched.password
                                                ? 'text-red'
                                                : 'text-text'
                                        }`}
                                        onClick={onShowPassword}
                                    />
                                    <Field
                                        id="password"
                                        name="password"
                                        placeholder="Contraseña"
                                        type="password"
                                        className={`w-full rounded-lg py-2 border px-12 ${
                                            errors.password && touched.password
                                                ? 'border-red focus:border-red bg-red/5 placeholder:text-red/50'
                                                : 'border-transparent focus:border-primary bg-dark-500 placeholder:text-text placeholder:text-sm'
                                        }`}
                                    />
                                </div>
                                <ErrorMessage
                                    name="password"
                                    component="small"
                                    className="text-red inline-block text-xs w-full mt-2"
                                />
                            </div>

                            <div>
                                <label
                                    htmlFor="confirmPassword"
                                    className="inline-block text-sm mb-2 text-white">
                                    Confirmar contraseña
                                </label>
                                <div className="relative">
                                    <LockClosedIcon
                                        className={`w-5 absolute top-1/2 -translate-y-1/2 left-4 ${
                                            errors.confirmPassword &&
                                            touched.confirmPassword
                                                ? 'text-red'
                                                : 'text-text'
                                        }`}
                                    />
                                    <EyeIcon
                                        className={`w-5 absolute top-1/2 -translate-y-1/2 right-4 ${
                                            errors.confirmPassword &&
                                            touched.confirmPassword
                                                ? 'text-red'
                                                : 'text-text'
                                        }`}
                                        onClick={onShowPassword}
                                    />
                                    <Field
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        placeholder="Contraseña"
                                        type="password"
                                        className={`w-full rounded-lg py-2 border px-12 ${
                                            errors.confirmPassword &&
                                            touched.confirmPassword
                                                ? 'border-red focus:border-red bg-red/5 placeholder:text-red/50'
                                                : 'border-transparent focus:border-primary bg-dark-500 placeholder:text-text placeholder:text-sm'
                                        }`}
                                    />
                                </div>
                                <ErrorMessage
                                    name="confirmPassword"
                                    component="small"
                                    className="text-red inline-block text-xs w-full mt-2"
                                />
                            </div>
                        </div>

                        <button
                            className="bg-primary font-bold text-white rounded-xl p-3 mt-10"
                            type="submit">
                            Crear cuenta
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    )
}
