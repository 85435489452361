import React from 'react'

export default function SkeletonProfileItem() {
    return (
        <div className="w-full flex flex-col items-center justify-center">
            <div className="bg-dark-400/35 h-4 w-full rounded-lg"></div>
            <div className="bg-dark-400/35 h-3 w-1/2 rounded-lg mt-4"></div>
        </div>
    )
}
