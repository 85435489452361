import React, { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { UserContext } from 'context/UserContext'

/**
 * Todas las rutas están protegidas para que el usuario
 * tenga que iniciar sesión, si no, se devuelve al login...
 */
export const IsLoggedMiddleware = () => {
    const {
        state: { user }
    } = useContext(UserContext)

    return user._id ? <Outlet /> : <Navigate to="/" />
}

/**
 * Vistas que el usuario no puede ver una vez iniciada
 * su sesión en la aplicaicón...
 */
export const IsLoggedOutMiddleware = () => {
    const {
        state: { user }
    } = useContext(UserContext)

    return user._id ? <Navigate to="/home" /> : <Outlet />
}

export default { IsLoggedMiddleware, IsLoggedOutMiddleware }
