import { PlusIcon } from '@heroicons/react/24/outline'

export default function ActionButton({ className, onClick, Icon = PlusIcon }) {
    return (
        <div
            onClick={onClick}
            className={`shrink-0 flex w-10 h-10 bg-primary rounded-lg cursor-pointer ${className}`}>
            <Icon className="w-5 m-auto text-white" />
        </div>
    )
}
