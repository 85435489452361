import api from 'services/api'

export const get = async (memberID, params) => {
    const response = await api.get('/kilometers', {
        params: {
            userID: memberID,
            ...params
        }
    })

    return response
}

export const getOne = async (kilometersID) => {
    const response = await api.get(`/kilometers/${kilometersID}`)

    return response
}

export const remove = async (kilometersID) => {
    const response = await api.delete(`/kilometers/${kilometersID}`)

    return response
}

export const add = async ({
    startingPoint,
    date,
    total,
    endingPoint,
    reason,
    placesID
}) => {
    const response = await api.post('/kilometers', {
        startingPoint,
        date,
        total,
        endingPoint,
        reason,
        placesID
    })

    return response
}

export const update = async (
    kilometersID,
    { startingPoint, date, total, endingPoint, reason, placesID }
) => {
    const response = await api.put(`/kilometers/${kilometersID}`, {
        startingPoint,
        date,
        total,
        endingPoint,
        reason,
        placesID
    })

    return response
}

export default {
    update,
    get,
    getOne,
    add,
    remove
}
