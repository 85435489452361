import api from 'services/api'

export const subscribe = async (subscription) => {
    const response = await api.post('/subscriptions', subscription)

    return response
}

export const unsubscribe = async () => {
    const response = await api.delete('/subscriptions')

    return response
}

export default {
    subscribe,
    unsubscribe
}
