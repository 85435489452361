import { useNavigate } from 'react-router-dom'

export default function NotFound() {
    const navigate = useNavigate()

    const onBackward = () => {
        navigate(-1)
    }

    return (
        <div className="w-full min-h-full flex flex-col px-6 py-10">
            <div className="flex flex-col justify-center text-center m-auto">
                <h1 className="font-bold mb-4 text-2xl text-white">
                    La página que estás buscando no se encuentra aquí
                </h1>
                <p>
                    Parece que tenemos problemas para encontrar la página que
                    estás buscando. Vuelve a intentarlo o contactanos
                </p>
            </div>

            <button
                className="bg-primary font-bold text-white rounded-xl p-3 mt-10"
                onClick={onBackward}>
                Regresar
            </button>
        </div>
    )
}
