import { commaNumber } from 'utils'

export default function ChapterItem({ data, onClick }) {
    return (
        <div
            className="flex justify-between items-center gap-4 p-4 py-[22px] bg-dark-500 rounded-lg"
            onClick={() => onClick(data)}>
            <div className="overflow-x-hidden">
                <div className="text-white text-sm capitalize whitespace-nowrap overflow-x-hidden text-ellipsis">
                    Capítulo {data.chapter}
                </div>
            </div>

            <div className="text-secundary font-bold shrink-0">
                {data.members} <span className="text-xs">miembros</span>
            </div>
        </div>
    )
}
