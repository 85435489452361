import React from 'react'
import BaseModal from './index'

const DisabledLeadershipModal = ({
    title,
    description,
    isVisible,
    onAccept,
    invitationMessage
}) => {
    return (
        <BaseModal
            title={title}
            description={description}
            isVisible={isVisible}
            onAccept={onAccept}
            showClose={false}>
            <div className="bg-dark-400 rounded-lg p-4 break-words select-all text-sm">
                {invitationMessage}
            </div>

            <div className="flex flex-col items-center">
                <button
                    className="bg-primary font-bold text-white rounded-xl p-3 mt-10 w-full"
                    onClick={onAccept}>
                    Ya compartí la invitación
                </button>
            </div>
        </BaseModal>
    )
}

export default DisabledLeadershipModal
