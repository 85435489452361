import React, { useState, useContext, useEffect, Fragment } from 'react'

import { EventsAPI } from 'api'
import { useNavigate, useParams } from 'react-router-dom'

import Header from 'components/Header'

import { UserContext } from 'context/UserContext'
import SkeletonItem from 'components/SkeletonItem'
import LeadershipItem from 'components/MemberItem/Leadership'
import { emitter } from 'utils'
import HelmetSVG from 'assets/helmet.svg'
import { TrashIcon } from '@heroicons/react/24/outline'
import ConfirmModal from 'components/Modals/Confirm'

export default function EventsUpdate() {
    const navigate = useNavigate()
    const { eventID } = useParams()
    const [event, setEvent] = useState({})
    const [loading, setLoading] = useState(true)
    const { state: userState } = useContext(UserContext)
    const [confirmModal, setConfirmModal] = useState(false)
    const [isMyEvent, setIsMyEvent] = useState(false)
    const [isJoined, setIsJoined] = useState(false)

    useEffect(() => {
        onEventDetails()
    }, [])

    const userIsJoinded = (userID, members) => {
        return !!members.find((member) => member._id === userID)
    }

    const onEventDetails = async () => {
        try {
            setLoading(true)

            const { data } = await EventsAPI.getOne(eventID)

            if (data.success) {
                const { event } = data
                const joined = userIsJoinded(userState.user._id, event.members)

                setIsJoined(joined)
                setIsMyEvent(event.user._id === userState.user._id)

                return setEvent(event)
            }

            if (data.message === 'EVENT_DOESNT_EXIST') {
                navigate('/events')
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const onBackward = () => {
        navigate(-1)
    }

    const onAbandonEvent = async () => {
        try {
            const { data } = await EventsAPI.abandon(eventID)

            if (data.success) {
                await onEventDetails()
            }
        } catch (error) {
            console.error(error)
        }
    }

    const onJoinEvent = async () => {
        try {
            const { data } = await EventsAPI.join(eventID)

            if (data.success) {
                await onEventDetails()
                emitter.emit('onOpenNotification', {
                    type: 'success',
                    title: 'Te has unido correctamente',
                    message: `Te has unido al evento del capítulo ${event.user?.chapter} con éxito`
                })
            }
        } catch (error) {
            console.error(error)
        }
    }

    const onMemberDetails = (member) => {
        navigate(`/members/profile/${member._id}`)
    }

    const onDeleteEvent = async () => {
        try {
            setLoading(true)

            const {
                data: { success }
            } = await EventsAPI.remove(eventID)

            if (success) {
                navigate('/events')
                onToggleConfirmModal()
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const onToggleConfirmModal = () => {
        setConfirmModal((value) => !value)
    }

    return (
        <Fragment>
            <ConfirmModal
                isVisible={confirmModal}
                onAccept={onDeleteEvent}
                onClose={onToggleConfirmModal}
                title="Eliminar evento"
                description="¿Deseas eliminar el evento? Se eliminará el evento para todos los miembros que se hayan unido."
            />
            <Header
                className="mb-12"
                onBackward={onBackward}
                onAction={() => setConfirmModal((value) => !value)}
                showAction={isMyEvent}
                actionIcon={TrashIcon}
                actionClass="bg-red"
            />

            <div className="mb-10">
                <h1 className="font-bold mb-6 text-2xl text-white">
                    Información del evento
                </h1>

                <div className="flex flex-col text-sm gap-2">
                    <div className="flex">
                        <div className="w-20 text-white">Lugar</div>
                        <div className="w-full capitalize">
                            {event.endingPoint}
                        </div>
                    </div>
                    <div className="flex">
                        <div className="w-20 text-white">Amigos</div>
                        <div className="w-full capitalize">
                            {event.friends
                                ? 'Si, amigos del club'
                                : 'No, solo coyotes'}
                        </div>
                    </div>
                    <div className="flex">
                        <div className="w-20 text-white">Creador</div>
                        <div className="w-full capitalize">
                            {event.user?.name} {event.user?.lastName}
                        </div>
                    </div>
                    <div className="flex">
                        <div className="w-20 text-white">Capítulo</div>
                        <div className="w-full capitalize">
                            {event.user?.chapter}
                        </div>
                    </div>
                    <div className="">
                        <div className="text-white">Detalles del evento:</div>
                        <div className="text">{event.description}</div>
                    </div>
                </div>
            </div>

            {!isMyEvent && !loading && (
                <Fragment>
                    {isJoined ? (
                        <button
                            className="border border-red font-bold text-red rounded-xl p-3 mb-10"
                            onClick={onAbandonEvent}>
                            Abandonar evento
                        </button>
                    ) : (
                        <button
                            className="border border-primary font-bold text-primary rounded-xl p-3 mb-10"
                            onClick={onJoinEvent}>
                            Unirse al evento
                        </button>
                    )}
                </Fragment>
            )}

            <div className="text-xs uppercase mb-6">MIEMBROS APUNTADOS</div>

            <div className="flex flex-col gap-6">
                {loading ? (
                    [...Array(5).keys()].map((index) => (
                        <SkeletonItem key={index} />
                    ))
                ) : (
                    <Fragment>
                        {event.members.length ? (
                            <Fragment>
                                {event.members?.map((item) => (
                                    <LeadershipItem
                                        key={item._id}
                                        data={item}
                                        isMe={item._id === userState.user._id}
                                        showKilometers={false}
                                        showPositon={false}
                                        onClick={onMemberDetails}
                                    />
                                ))}
                            </Fragment>
                        ) : (
                            <div className="bg-dark-500 rounded-lg p-4 flex items-start">
                                <img src={HelmetSVG} className="mr-4" />
                                <div>
                                    <div className="text-white text-sm">
                                        Sin miembros
                                    </div>
                                    <div className="text-text text-xs">
                                        Los miembros que se hayan apuntado al
                                        evento aparecerán aquí
                                    </div>
                                </div>
                            </div>
                        )}
                    </Fragment>
                )}
            </div>
        </Fragment>
    )
}
