import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'

export default function GooglePlacesInput({
    field,
    form,
    ...props
}) {
    const { placePredictions, getPlacePredictions } = usePlacesService({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        debounce: 500,
        language: 'es-419'
    })

    const onChangeInput = ($event) => {
        const input = $event.target.value
        form.setFieldValue(field.name, input)
        getPlacePredictions({ input })
    }

    const onSelect = ($event) => {
        const input = $event.currentTarget.innerText
        form.setFieldValue(field.name, input)
        getPlacePredictions({ input: null })
    }

    const onBlurInput = () => {
        getPlacePredictions({ input: null })
    }

    return (
        <div className="relative">
            <input
                placeholder="Ingresa una ubicación"
                {...field}
                {...props}
                onChange={onChangeInput}
                onBlur={onBlurInput}
            />

            {placePredictions.length > 0 && (
                <div className="absolute w-full bg-dark-300 z-20 px-4 rounded-lg top-[calc(100%+8px)] max-h-48 overflow-y-auto shadow-2xl">
                    {placePredictions.map((item) => (
                        <div
                            key={item.place_id}
                            value={item.description}
                            className="text-sm text-text py-2 border-dark-400 [&:not(:last-child)]:border-b flex"
                            onMouseDown={onSelect}>
                            <MagnifyingGlassIcon className="w-4 shrink-0 mr-4" />
                            <div className="whitespace-nowrap overflow-x-hidden text-ellipsis">
                                {item.description}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}
