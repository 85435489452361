import React, { Fragment, useContext, useEffect, useState } from 'react'
import { EventsAPI } from 'api'
import { useNavigate } from 'react-router-dom'
import Header from 'components/Header'

import SkeletonItem from 'components/SkeletonItem'
import { UserContext } from 'context/UserContext'
import EventItem from 'components/EventItem'
import CalendarSVG from 'assets/calendar.svg'

export default function Events() {
    const navigate = useNavigate()
    const { state: userState } = useContext(UserContext)
    const [events, setEvents] = useState([])
    const [loading, setLoading] = useState(false)
    const [filter, setFilter] = useState('all')

    useEffect(() => {
        getFilteredEvents()
    }, [filter])

    const getFilteredEvents = async () => {
        try {
            setLoading(true)

            const { data } = await EventsAPI.get({
                filter
            })

            setEvents(data.events)
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const onBackward = () => {
        navigate('/home')
    }

    const onAction = () => {
        navigate('/events/add')
    }

    const FILTER_ITEMS = [
        {
            label: 'Todos',
            tag: 'all',
            action: function () {
                setFilter(this.tag)
            }
        },
        {
            label: 'Capítulo',
            tag: 'chapter',
            action: function () {
                setFilter(this.tag)
            }
        }
    ]

    const onSelectEvent = (event) => {
        navigate(event._id)
    }

    return (
        <Fragment>
            <Header
                className="mb-12"
                onBackward={onBackward}
                onAction={onAction}
                showAction={true}
                title="Eventos"
            />

            <div className="p-2 bg-dark-500 rounded-2xl flex text-center text-sm mb-10">
                {FILTER_ITEMS.map((menu) => (
                    <div
                        key={menu.tag}
                        className={`w-1/2 rounded-xl py-2 ${
                            filter === menu.tag ? 'bg-dark-400 text-white' : ''
                        }`}
                        onClick={() => menu.action.call(menu)}>
                        {menu.label}
                    </div>
                ))}
            </div>

            <div className="flex flex-col gap-6">
                {loading ? (
                    [...Array(10).keys()].map((index) => (
                        <SkeletonItem key={index} />
                    ))
                ) : (
                    <Fragment>
                        {events.length ? (
                            <Fragment>
                                {events.map((item) => (
                                    <EventItem
                                        key={item._id}
                                        data={item}
                                        onClick={onSelectEvent}
                                    />
                                ))}
                            </Fragment>
                        ) : (
                            <div className="text-center flex flex-col items-center mt-20">
                                <img src={CalendarSVG} className="w-16 mb-4" />
                                <div>
                                    <div className="text-white mb-2">
                                        No hay eventos recientes
                                    </div>
                                    <div className="text-text text-sm">
                                        Los eventos creados por otros miembros
                                        del club aparecerán aquí y podrás unirte
                                    </div>
                                </div>
                            </div>
                        )}
                    </Fragment>
                )}
            </div>
        </Fragment>
    )
}
