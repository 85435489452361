import { commaNumber } from 'utils'

export default function MemberItemLeadership({
    position,
    data,
    isMe,
    onClick,
    showKilometers = true,
    showPositon = true
}) {
    return (
        <div
            className={`rounded-lg flex items-center p-4 gap-4 ${isMe ? 'bg-dark-400' : 'bg-dark-500'
                }`}
            onClick={() => onClick ? onClick(data) : ''}>
            {showPositon && (
                <div
                    className={`shrink-0 text-sm flex h-10 w-10 rounded-lg items-center justify-center ${isMe ? 'bg-dark-500/50' : 'bg-dark-400'
                        }`}>
                    #{position}
                </div>
            )}
            <div className="overflow-x-hidden mr-auto">
                <div className="text-white text-sm capitalize whitespace-nowrap overflow-x-hidden text-ellipsis">
                    {data.name + ' ' + data.lastName}
                    {isMe ? ' (Tú)' : ''}
                </div>
                <div className="text-text text-xs capitalize whitespace-nowrap overflow-x-hidden text-ellipsis">
                    {data.chapter} / {data.role}
                </div>
            </div>

            {showKilometers && (
                <div className="text-secundary font-bold shrink-0">
                    {commaNumber(data.totalKilometers)}{' '}
                    <span className="text-xs">kms</span>
                </div>
            )}
        </div>
    )
}
