import 'swiper/css'
import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'

import SkeletonCardItem from 'components/SkeletonItem/Card'
import ChaptersItemCard from 'components/ChaptersItem/Card'

export default function HomeChapters({ loading, chapters }) {
    const navigate = useNavigate()

    const onChapters = () => {
        return navigate(`/chapters`)
    }

    const onChapter = (chapter) => {
        return navigate(`/chapters/${chapter}`)
    }

    return (
        <Fragment>
            <div>
                <div className="font-semibold flex justify-between items-center mb-6">
                    <div className="text-xs uppercase">
                        CAPÍTULOS HUEHUECOYOTL
                    </div>
                    <div className="text-primary text-sm" onClick={onChapters}>
                        Ver todos
                    </div>
                </div>

                <Swiper
                    className="cursor-grab"
                    spaceBetween={24}
                    slidesPerView={2.5}>
                    {loading ? (
                        [...Array(5).keys()].map((index) => (
                            <SwiperSlide key={index}>
                                <SkeletonCardItem key={index} />
                            </SwiperSlide>
                        ))
                    ) : (
                        <Fragment>
                            {chapters.map((item, index) => (
                                <SwiperSlide key={item.chapter + index}>
                                    <ChaptersItemCard
                                        key={item.chapter}
                                        data={item}
                                        onClick={() => onChapter(item.chapter)}
                                    />
                                </SwiperSlide>
                            ))}
                        </Fragment>
                    )}
                </Swiper>
            </div>
        </Fragment>
    )
}
