import React, { useRef, useState, useContext, useEffect, Fragment } from 'react'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { MapPinIcon } from '@heroicons/react/24/outline'
import { NumericFormat } from 'react-number-format'
import { KilometersAPI } from 'api'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import Header from 'components/Header'
import Datepicker from 'components/Datepicker'
import { emitter, numberWithoutComma } from 'utils'
import { UserContext } from 'context/UserContext'
import PostItSVG from 'assets/post_it.svg'
import GooglePlacesInput from 'components/Input/GooglePlaces'

export default function KilometersAdd() {
    const formRef = useRef()
    const navigate = useNavigate()
    const { memberID } = useParams()
    const { isMyProfile } = useContext(UserContext)
    const [currentMonth] = useState(() => dayjs().format('MMMM'))
    const [isMe] = useState(isMyProfile(memberID))

    useEffect(() => {
        if (!isMe) {
            return navigate('/home')
        }
    }, [])

    const onAdd = async ({
        startingPoint,
        date,
        total,
        endingPoint,
        reason
    }) => {
        const { data } = await KilometersAPI.add({
            startingPoint,
            date,
            total: numberWithoutComma(total),
            endingPoint,
            reason
        })

        if (data.success) {
            emitter.emit('onOpenNotification', {
                type: 'success',
                title: 'Kilómetros agregados',
                message: `Tus kilómetros fueron agregados correctamente`
            })

            onBackward()
        }
    }

    const onBackward = () => {
        navigate(-1)
    }

    const initialValues = {
        startingPoint: '',
        date: dayjs().toDate(),
        total: '',
        endingPoint: '',
        reason: ''
    }

    const validationSchema = Yup.object().shape({
        startingPoint: Yup.string().required('Este campo es requerido'),
        date: Yup.string().required('Este campo es requerido'),
        total: Yup.string()
            .required('Este campo es requerido')
            .test('min-km', 'Tus kilómetros deben ser mayor a 0', (km) => {
                if (km) {
                    let amount = numberWithoutComma(km)
                    if (amount > 0) {
                        return true
                    }
                }
            }),
        endingPoint: Yup.string().required('Este campo es requerido'),
        reason: Yup.string()
    })

    return (
        <Fragment>
            <Header className="mb-12" onBackward={onBackward} />
            <div className="mb-10">
                <h1 className="font-bold mb-2 text-2xl text-white">
                    Agregar kilómetros
                </h1>
                <p>
                    Tus kilómetros se contarán para el mes de {currentMonth} y
                    se agregaran a tu kilometraje anual.
                </p>
            </div>

            <Formik
                initialValues={initialValues}
                onSubmit={onAdd}
                validationSchema={validationSchema}
                innerRef={formRef}>
                {({ errors, touched }) => (
                    <Form className="flex flex-col grow" autoComplete="off">
                        <div className="mb-auto flex flex-col gap-6">
                            <div>
                                <label
                                    htmlFor="endingPoint"
                                    className="inline-block text-sm mb-2 text-white">
                                    ¿A qué lugar fuiste?
                                </label>
                                <div className="relative">
                                    <MapPinIcon
                                        className={`w-5 absolute top-1/2 -translate-y-1/2 left-4 z-10 ${
                                            errors.endingPoint &&
                                            touched.endingPoint
                                                ? 'text-red'
                                                : 'text-text'
                                        }`}
                                    />
                                    <Field
                                        name="endingPoint"
                                        component={GooglePlacesInput}
                                        className={`w-full rounded-lg py-2 px-4 border pl-12 ${
                                            errors.endingPoint &&
                                            touched.endingPoint
                                                ? 'border-red focus:border-red bg-red/5 placeholder:text-red/50'
                                                : 'border-transparent focus:border-primary bg-dark-500 placeholder:text-text placeholder:text-sm'
                                        }`}
                                    />
                                </div>
                                <ErrorMessage
                                    name="endingPoint"
                                    component="small"
                                    className="text-red inline-block text-xs w-full mt-2"
                                />
                            </div>

                            <div>
                                <label
                                    htmlFor="startingPoint"
                                    className="inline-block text-sm mb-2 text-white">
                                    ¿De dónde partiste?
                                </label>
                                <div className="relative">
                                    <MapPinIcon
                                        className={`w-5 absolute top-1/2 -translate-y-1/2 left-4 z-10 ${
                                            errors.startingPoint &&
                                            touched.startingPoint
                                                ? 'text-red'
                                                : 'text-text'
                                        }`}
                                    />
                                    <Field
                                        name="startingPoint"
                                        component={GooglePlacesInput}
                                        className={`w-full rounded-lg py-2 px-4 border pl-12 ${
                                            errors.startingPoint &&
                                            touched.startingPoint
                                                ? 'border-red focus:border-red bg-red/5 placeholder:text-red/50'
                                                : 'border-transparent focus:border-primary bg-dark-500 placeholder:text-text placeholder:text-sm'
                                        }`}
                                    />
                                </div>
                                <ErrorMessage
                                    name="startingPoint"
                                    component="small"
                                    className="text-red inline-block text-xs w-full mt-2"
                                />
                            </div>

                            <div>
                                <label
                                    htmlFor="total"
                                    className="inline-block text-sm mb-2 text-white">
                                    Kilómetros recorridos{' '}
                                    <span className="text-dark-200">
                                        (ida y vuelta)
                                    </span>
                                </label>
                                <div className="relative">
                                    <div
                                        className={`absolute top-1/2 -translate-y-1/2 right-4 text-sm ${
                                            errors.total && touched.total
                                                ? 'text-red'
                                                : 'text-white'
                                        }`}>
                                        <span className="text-dark-200">
                                            /kms
                                        </span>
                                    </div>
                                    <Field name="total" id="total">
                                        {({ field, ...props }) => (
                                            <NumericFormat
                                                {...field}
                                                {...props}
                                                autoComplete="off"
                                                id="total"
                                                thousandSeparator=","
                                                decimalScale={0}
                                                placeholder="0"
                                                className={`w-full rounded-lg py-2 px-4 border pr-16 ${
                                                    errors.total &&
                                                    touched.total
                                                        ? 'border-red focus:border-red bg-red/5 placeholder:text-red/50'
                                                        : 'border-transparent focus:border-primary bg-dark-500 placeholder:text-text placeholder:text-sm'
                                                }`}
                                            />
                                        )}
                                    </Field>
                                </div>
                                <ErrorMessage
                                    name="total"
                                    component="small"
                                    className="text-red inline-block text-xs w-full mt-2"
                                />
                            </div>

                            <div>
                                <label
                                    htmlFor="date"
                                    className="inline-block text-sm mb-2 text-white">
                                    Fecha del viaje
                                </label>
                                <Field name="date" component={Datepicker} />
                                <ErrorMessage
                                    name="date"
                                    component="small"
                                    className="text-red inline-block text-xs w-full mt-2"
                                />
                            </div>

                            <div>
                                <label
                                    htmlFor="reason"
                                    className="inline-block text-sm mb-2 text-white">
                                    Razón del viaje{' '}
                                    <span className="text-dark-200">
                                        (opcional)
                                    </span>
                                </label>
                                <Field
                                    id="reason"
                                    name="reason"
                                    as="textarea"
                                    rows="3"
                                    className={`w-full rounded-lg py-2 px-4 border ${
                                        errors.reason && touched.reason
                                            ? 'border-red focus:border-red bg-red/5 placeholder:text-red/50'
                                            : 'border-transparent focus:border-primary bg-dark-500 placeholder:text-text placeholder:text-sm'
                                    }`}
                                />
                                <ErrorMessage
                                    name="reason"
                                    component="small"
                                    className="text-red inline-block text-xs w-full mt-2"
                                />
                            </div>
                        </div>

                        <div className="bg-dark-500 rounded-lg p-4 flex items-start mt-20">
                            <img src={PostItSVG} className="mr-4" />
                            <div>
                                <div className="text-white text-sm">
                                    Detalles de la rodada
                                </div>
                                <div className="text-text text-xs">
                                    Trata de ser lo más especifico con el lugar
                                    de destino y los kilómetros recorridos
                                </div>
                            </div>
                        </div>

                        <button
                            className="bg-primary font-bold text-white rounded-xl p-3 mt-10"
                            type="submit">
                            Agregar kilómetros
                        </button>
                    </Form>
                )}
            </Formik>
        </Fragment>
    )
}
