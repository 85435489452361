import { commaNumber, formatDate } from 'utils'
import Hammer from 'hammerjs'
import { TrashIcon } from '@heroicons/react/24/outline'
import { useEffect, useRef } from 'react'

export default function KilometersItem({
    data,
    onClick,
    onRemove,
    showRemove
}) {
    const wrapper = useRef()

    useEffect(() => {
        const hammer = new Hammer(wrapper.current)

        if (showRemove) {
            hammer.on('swipeleft swiperight press', onSwipeEvents)
        }

        return () => {
            hammer.off('swipeleft swiperight press')
        }
    }, [])

    let swipeStatus = 'middle'

    const onSwipeEvents = ($event) => {
        if ($event.type === 'swiperight' && swipeStatus === 'middle') {
            wrapper.current.style.transform = 'translatex(0px)'
            swipeStatus = 'right'
        } else if ($event.type === 'swipeleft' && swipeStatus === 'right') {
            wrapper.current.style.transform = 'translatex(-64px)'
            swipeStatus = 'middle'
        }
    }
    const onRemoveItem = ($event) => {
        $event.stopPropagation()
        wrapper.current.style.transform = 'translatex(-64px)'
        swipeStatus = 'middle'
        onRemove(data)
    }

    return (
        <div
            className="bg-dark-500 rounded-lg overflow-hidden"
            onClick={() => onClick(data)}>
            <div
                ref={wrapper}
                className="flex -translate-x-16 w-[calc(100%+64px)] transition">
                <div
                    className="w-16 shrink-0 bg-red flex rounded-tl-lg rounded-bl-lg"
                    onClick={onRemoveItem}>
                    <TrashIcon className="w-4 m-auto text-white pointer-events-none" />
                </div>

                <div className="flex justify-between items-center gap-4 p-4 pointer-events-none w-[calc(100%-64px)] ">
                    <div className="overflow-x-hidden">
                        <div className="text-white text-sm capitalize whitespace-nowrap overflow-x-hidden text-ellipsis">
                            {data.endingPoint}
                        </div>
                        <div className="text-text text-xs whitespace-nowrap overflow-x-hidden text-ellipsis">
                            {formatDate(
                                data.date,
                                'dddd[,] DD [de] MMMM [del] YYYY'
                            )}
                        </div>
                    </div>

                    <div className="text-secundary font-bold shrink-0">
                        {commaNumber(data.total)}{' '}
                        <span className="text-xs">kms</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
