import React, { Fragment, useState, useRef, useEffect } from 'react'

export default function Datepicker({ field, form, ...params }) {
    const dayInput = useRef()
    const monthInput = useRef()
    const yearInput = useRef()
    const [currentDate, setCurrentDate] = useState(field.value)

    useEffect(() => {
        setCurrentDate(field.value)
        dayInput.current.value = field.value.getDate()
        monthInput.current.value = field.value.getMonth() + 1
        yearInput.current.value = field.value.getFullYear()
    }, [field])

    /**
     * Listado de los días partiendo del mes seleccionado en currentDate
     */
    const DAYS = new Array(
        new Date(
            currentDate.getFullYear(),
            currentDate.getMonth() + 1,
            0
        ).getDate()
    )
        .fill(1)
        .map((v, i) => v + i)

    /**
     * Listado de todos los meses desplegados en el input
     */
    const MONTHS = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
    ].map((month) => {
        /** Abreviar los meses cuando la pantalla sea menor 380px */
        if (window.innerWidth < 380) {
            return month.substring(0, 3)
        }

        return month
    })

    /**
     * Generar listado de los últimos 100 años apartir del actual
     */
    const YEARS = []
    const currentYear = new Date().getFullYear()
    for (let i = currentYear; i > currentYear - 100; i--) YEARS.push(i)

    const onChangeDate = (type, event) => {
        let newDate
        let changedData = Number(event.currentTarget.value)

        if (type === 'day') {
            newDate = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                changedData
            )
        } else if (type === 'month') {
            const selectedMonth = new Date(
                currentDate.getFullYear(),
                changedData,
                0
            )

            let maxDayInSelectedMonth = selectedMonth.getDate()
            let getMaxDay = Math.min(
                currentDate.getDate(),
                maxDayInSelectedMonth
            )
            newDate = new Date(
                currentDate.getFullYear(),
                changedData - 1,
                getMaxDay
            )
        } else if (type === 'year') {
            const selectedYear = new Date(
                changedData,
                currentDate.getMonth() + 1,
                0
            )
            let maxDayInSelectedMonth = selectedYear.getDate()
            let day = Math.min(currentDate.getDate(), maxDayInSelectedMonth)
            newDate = new Date(changedData, currentDate.getMonth(), day)
        }

        form.setFieldValue(field.name, newDate)
    }

    return (
        <Fragment>
            <div className="flex gap-4">
                <div className="w-1/2">
                    <select
                        disabled={params.disabled}
                        ref={monthInput}
                        onChange={(event) => onChangeDate('month', event)}
                        id="month"
                        className={`w-full rounded-lg py-2 px-4 border ${
                            form.errors[field.name] && form.touched[field.name]
                                ? 'border-red focus:border-red bg-red/5 placeholder:text-red/50'
                                : 'border-transparent focus:border-primary bg-dark-500 placeholder:text-text placeholder:text-sm'
                        }`}>
                        {MONTHS.map((month, index) => {
                            return (
                                <option key={month} value={index + 1}>
                                    {month}
                                </option>
                            )
                        })}
                    </select>
                </div>
                <div className="w-1/4 min-w-[65px]">
                    <select
                        disabled={params.disabled}
                        ref={dayInput}
                        onChange={(event) => onChangeDate('day', event)}
                        id="day"
                        className={`w-full rounded-lg py-2 px-4 border ${
                            form.errors[field.name] && form.touched[field.name]
                                ? 'border-red focus:border-red bg-red/5 placeholder:text-red/50'
                                : 'border-transparent focus:border-primary bg-dark-500 placeholder:text-text placeholder:text-sm'
                        }`}>
                        {DAYS.map((day) => {
                            return (
                                <option key={day} value={day}>
                                    {day}
                                </option>
                            )
                        })}
                    </select>
                </div>
                <div className="w-1/4 min-w-[85px]">
                    <select
                        disabled={params.disabled}
                        ref={yearInput}
                        onChange={(event) => onChangeDate('year', event)}
                        id="year"
                        className={`w-full rounded-lg py-2 px-4 border ${
                            form.errors[field.name] && form.touched[field.name]
                                ? 'border-red focus:border-red bg-red/5 placeholder:text-red/50'
                                : 'border-transparent focus:border-primary bg-dark-500 placeholder:text-text placeholder:text-sm'
                        }`}>
                        {YEARS.map((year) => {
                            return <option key={year}>{year}</option>
                        })}
                    </select>
                </div>
            </div>
        </Fragment>
    )
}
