import React from 'react'

export default function SkeletonCardItem() {
    return (
        <div className="shrink-0 grow-0 bg-dark-500/35 rounded-lg p-4 w-full h-36 flex flex-col justify-center items-center">
            <div className="bg-dark-400/35 h-3 w-1/2 rounded-lg"></div>
            <div className="bg-dark-400/35 h-3 w-full rounded-lg my-3"></div>
            <div className="bg-dark-400/35 h-3 w-1/2 rounded-lg"></div>
        </div>
    )
}
