import React, { Fragment, useContext, useEffect, useState } from 'react'
import { ContributionsAPI } from 'api'
import { useNavigate, useParams } from 'react-router-dom'
import Header from 'components/Header'

import ContributionItem from 'components/ContributionItem'
import { UserContext } from 'context/UserContext'
import { USER_ROLES } from 'constants'
import ContributionSVG from 'assets/contribution.svg'
import SkeletonItem from 'components/SkeletonItem'
import { emitter } from 'utils'

export default function Contributions() {
    const navigate = useNavigate()
    const { memberID } = useParams()
    const { state: userState } = useContext(UserContext)
    const [contributions, setContributions] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        onGetContributions()
    }, [])

    const onGetContributions = async () => {
        const { data } = await ContributionsAPI.get(memberID)
        setContributions(data.contributions)
        setLoading(false)
    }

    const onBackward = () => {
        navigate(-1)
    }

    const onAction = () => {
        navigate('add')
    }

    const onUpdateContribution = (payload) => {
        if (
            [USER_ROLES.SECRETARIO, USER_ROLES.ADMIN].includes(
                userState.user.role
            )
        ) {
            navigate(`update/${payload._id}`)
        }
    }

    const onRemoveContribution = async (payload) => {
        const { data } = await ContributionsAPI.remove(payload._id)

        if (data.success) {
            const newContributions = [...contributions]
            const deletedIndex = newContributions.findIndex(
                (item) => item._id === data.contribution._id
            )

            newContributions.splice(deletedIndex, 1)

            setContributions(newContributions)

            emitter.emit('onOpenNotification', {
                type: 'success',
                title: 'Aportación eliminada',
                message: `La aportación fue eliminada correctamente`
            })
        }
    }

    return (
        <Fragment>
            <Header
                className="mb-12"
                onBackward={onBackward}
                onAction={onAction}
                showAction={[USER_ROLES.SECRETARIO, USER_ROLES.ADMIN].includes(
                    userState.user.role
                )}
                title="Aportaciones"
            />

            <div className="flex flex-col gap-6 ">
                {loading ? (
                    [...Array(10).keys()].map((index) => (
                        <SkeletonItem key={index} />
                    ))
                ) : (
                    <Fragment>
                        {contributions.length ? (
                            <Fragment>
                                {contributions.map((item) => (
                                    <ContributionItem
                                        key={item._id}
                                        data={item}
                                        onClick={onUpdateContribution}
                                        onRemove={onRemoveContribution}
                                        showRemove={[
                                            USER_ROLES.SECRETARIO,
                                            USER_ROLES.ADMIN
                                        ].includes(userState.user.role)}
                                    />
                                ))}
                            </Fragment>
                        ) : (
                            <div className="text-center flex flex-col items-center mt-20">
                                <img
                                    src={ContributionSVG}
                                    className="w-16 mb-4"
                                />
                                <div>
                                    <div className="text-white mb-2">
                                        Aún no hay aportaciones
                                    </div>
                                    <div className="text-text text-sm">
                                        Cada vez que un miembro realice una
                                        contribución al capítulo, esta se verá
                                        reflejada aquí.
                                    </div>
                                </div>
                            </div>
                        )}
                    </Fragment>
                )}
            </div>
        </Fragment>
    )
}
