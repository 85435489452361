import registerServiceWorker from 'services/worker'
import 'swiper/css'

import React, { Fragment, useContext, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { UserContext } from 'context/UserContext'

import { Swiper, SwiperSlide } from 'swiper/react'

import MemberItemCard from 'components/MemberItem/Card'
import SkeletonCardItem from 'components/SkeletonItem/Card'

export default function HomeMembers({ loading, members }) {
    const navigate = useNavigate()
    const { state: userState } = useContext(UserContext)

    const onMembers = () => {
        return navigate(`/members`)
    }

    const onMembersProfileDetails = (memebrID) => {
        return navigate(`/members/profile/${memebrID}`)
    }

    return (
        <Fragment>
            <div>
                <div className="font-semibold flex justify-between items-center mb-6">
                    <div className="text-xs uppercase">
                        MIEMBROS {userState.user.chapter}
                    </div>
                    <div className="text-primary text-sm" onClick={onMembers}>
                        Ver todos
                    </div>
                </div>

                <Swiper
                    className="cursor-grab"
                    spaceBetween={24}
                    slidesPerView={2.5}>
                    {loading ? (
                        [...Array(5).keys()].map((index) => (
                            <SwiperSlide key={index}>
                                <SkeletonCardItem key={index} />
                            </SwiperSlide>
                        ))
                    ) : (
                        <Fragment>
                            {members.map((item, index) => (
                                <SwiperSlide key={item._id + index}>
                                    <MemberItemCard
                                        key={item._id}
                                        data={item}
                                        onClick={() =>
                                            onMembersProfileDetails(item._id)
                                        }
                                    />
                                </SwiperSlide>
                            ))}
                        </Fragment>
                    )}
                </Swiper>
            </div>
        </Fragment>
    )
}
