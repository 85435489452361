import api from 'services/api'

export const get = async () => {
    const response = await api.get('/leadership')

    return response
}

export const enable = async () => {
    const response = await api.post('/leadership/enable')

    return response
}

export default {
    get,
    enable
}
