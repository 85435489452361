import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { AccountAPI } from 'api'
import { UserContext } from 'context/UserContext'
import { useNavigate } from 'react-router-dom'
import BackButton from 'components/Buttons/Back'
import { capitalizeText, emitter } from 'utils'
import { USER_ROLES, CHAPTERS } from 'constants'
import SandClockSVG from 'assets/sand_clock.svg'
import InvitationModal from 'components/Modals/Invitation'

export default function Invite() {
    const navigate = useNavigate()
    const formRef = useRef()
    const { state: userState } = useContext(UserContext)
    const [showInvitationModal, setShowInvitationModal] = useState(false)
    const [invitationMessage, setInvitationMessage] = useState('')
    const [userRoles] = useState(() => {
        const roles = { ...USER_ROLES }

        if (userState.user.role !== USER_ROLES.ADMIN) {
            delete roles.TLATOANI
            delete roles.ADMIN
        }

        return Object.values(roles)
    })

    useEffect(() => {
        if (
            ![
                USER_ROLES.VICEPRESIDENTE,
                USER_ROLES.PRESIDENTE,
                USER_ROLES.ADMIN,
                USER_ROLES.TLATOANI
            ].includes(userState.user.role)
        ) {
            navigate('/home')
        }
    }, [])

    const onInvite = async ({ role, chapter }) => {
        const { data } = await AccountAPI.invitation({ role, chapter })

        if (data.success) {
            const name = capitalizeText(userState.user.name, true)
            const message = `${name} te esta invitando a que te unas al capítulo ${chapter} como ${role}. Utiliza este link en tu celular para registrarte: ${process.env.REACT_APP_CLIENT}/signup?uid=${data.uid}`

            setInvitationMessage(message)
            setShowInvitationModal(true)
        }
    }

    const onBackward = () => {
        navigate(-1)
    }

    const onInvitationCopied = () => {
        emitter.emit('onOpenNotification', {
            type: 'success',
            title: 'Invitación generada',
            message: `La invitación se generó correctamente`
        })

        onBackward()
    }

    const initialValues = {
        role: '',
        chapter: userState.user.chapter
    }

    const validationSchema = Yup.object().shape({
        role: Yup.string().required('Este campo es requerido'),
        chapter: Yup.string().required('Este campo es requerido')
    })

    return (
        <Fragment>
            <BackButton className="mb-12" onClick={onBackward} />

            <InvitationModal
                title="Invitación generada"
                description="Copia y comparte el siguiente texto con el mimebro"
                isVisible={showInvitationModal}
                onAccept={onInvitationCopied}
                invitationMessage={invitationMessage}
            />

            <div className="mb-10">
                <h1 className="font-bold mb-2 text-2xl text-white">
                    Generar invitación
                </h1>
                <p>
                    Crea una invitación para que un miembro del club se una al
                    capítulo. Selecciona el rango y envía la invitación al
                    usuario.
                </p>
            </div>
            <Formik
                initialValues={initialValues}
                onSubmit={onInvite}
                validationSchema={validationSchema}
                innerRef={formRef}>
                {({ errors, touched }) => (
                    <Form className="flex flex-col grow" autoComplete="off">
                        <div className="mb-auto flex flex-col gap-6">
                            <div>
                                <label
                                    htmlFor="chapter"
                                    className="inline-block text-sm mb-2 text-white">
                                    Capítulo
                                </label>
                                <Field
                                    id="chapter"
                                    name="chapter"
                                    as="select"
                                    disabled={
                                        ![
                                            USER_ROLES.ADMIN,
                                            USER_ROLES.TLATOANI
                                        ].includes(userState.user.role)
                                    }
                                    className={`w-full rounded-lg py-2 px-4 border capitalize ${
                                        errors.chapter && touched.chapter
                                            ? 'border-red focus:border-red bg-red/5 placeholder:text-red/50'
                                            : 'border-transparent focus:border-primary bg-dark-500 placeholder:text-text placeholder:text-sm'
                                    }`}>
                                    <option defaultValue hidden></option>
                                    {Object.values(CHAPTERS).map((item) => (
                                        <option key={item}>{item}</option>
                                    ))}
                                </Field>
                                <ErrorMessage
                                    name="chapter"
                                    component="small"
                                    className="text-red inline-block text-xs w-full mt-2"
                                />
                            </div>

                            <div>
                                <label
                                    htmlFor="role"
                                    className="inline-block text-sm mb-2 text-white">
                                    Cargo del miembro
                                </label>
                                <Field
                                    id="role"
                                    name="role"
                                    as="select"
                                    className={`w-full rounded-lg py-2 px-4 border capitalize ${
                                        errors.role && touched.role
                                            ? 'border-red focus:border-red bg-red/5 placeholder:text-red/50'
                                            : 'border-transparent focus:border-primary bg-dark-500 placeholder:text-text placeholder:text-sm'
                                    }`}>
                                    <option defaultValue hidden></option>
                                    {userRoles.map((item) => (
                                        <option key={item}>{item}</option>
                                    ))}
                                </Field>
                                <ErrorMessage
                                    name="role"
                                    component="small"
                                    className="text-red inline-block text-xs w-full mt-2"
                                />
                            </div>
                        </div>

                        <div className="bg-dark-500 rounded-lg p-4 flex items-start mt-20">
                            <img src={SandClockSVG} className="mr-4" />
                            <div>
                                <div className="text-white text-sm">
                                    Tiempo de expiración
                                </div>
                                <div className="text-text text-xs">
                                    Las invitaciones tienen 24H de expiración,
                                    tendrás que generar otra pasado el tiempo
                                </div>
                            </div>
                        </div>

                        <button
                            className="bg-primary font-bold text-white rounded-xl p-3 mt-10"
                            type="submit">
                            Generar invitación
                        </button>
                    </Form>
                )}
            </Formik>
        </Fragment>
    )
}
