import api from 'services/api'

export const add = async (memberID, { concept, date, total }) => {
    const response = await api.post(`/contributions/${memberID}`, {
        concept,
        date,
        total
    })

    return response
}

export const get = async (memberID) => {
    const response = await api.get('/contributions', {
        params: {
            userID: memberID
        }
    })

    return response
}

export const getOne = async (contributionID) => {
    const response = await api.get(`/contributions/${contributionID}`)

    return response
}

export const remove = async (contributionID) => {
    const response = await api.delete(`/contributions/${contributionID}`)

    return response
}

export const update = async (contributionID, { concept, date, total }) => {
    const response = await api.put(`/contributions/${contributionID}`, {
        concept,
        date,
        total
    })

    return response
}

export default {
    add,
    get,
    getOne,
    remove,
    update
}
