import dayjs from 'dayjs'
import React, { Fragment, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserContext } from 'context/UserContext'
import { firstName } from 'utils'

import ActionButton from 'components/Buttons/Action'

export default function HomeHeader() {
    const navigate = useNavigate()
    const { state: userState } = useContext(UserContext)

    const onAddKilometers = () => {
        return navigate(`/kilometers/${userState.user._id}/add`)
    }

    return (
        <Fragment>
            <div className="flex items-center">
                <div className="mr-auto">
                    <div className="text-lg text-white font-bold capitalize">
                        Hola {firstName(userState.user.name)}
                    </div>
                    <div className="text-sm ">
                        {dayjs().format('[A] DD [de] MMMM [del] YYYY')}
                    </div>
                </div>

                <ActionButton onClick={onAddKilometers} />
            </div>
        </Fragment>
    )
}
