import React from 'react'

export default function SkeletonDashboardItem() {
    return (
        <div className="bg-dark-500/35 rounded-lg p-4 h-[160px] flex gap-4">
            <div className="flex flex-col gap-8 w-1/2">
                <div>
                    <div className="bg-dark-400/35 h-3 w-1/2 rounded-lg"></div>
                    <div className="bg-dark-400/35 h-3 w-full rounded-lg mt-3"></div>
                </div>
                <div>
                    <div className="bg-dark-400/35 h-3 w-1/2 rounded-lg"></div>
                    <div className="bg-dark-400/35 h-3 w-full rounded-lg mt-3"></div>
                </div>
            </div>
            <div className="flex flex-col gap-8 w-1/2">
                <div>
                    <div className="bg-dark-400/35 h-3 w-1/2 rounded-lg"></div>
                    <div className="bg-dark-400/35 h-3 w-full rounded-lg mt-3"></div>
                </div>
                <div></div>
            </div>
        </div>
    )
}
