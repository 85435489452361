import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import Routes from './routes'
import 'url-search-params-polyfill'
import Notification from 'components/Notification'
import { UserProvider } from 'context/UserContext'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
    <UserProvider>
        <BrowserRouter>
            <App />
            <Routes />
            <Notification />
        </BrowserRouter>
    </UserProvider>
)
