import React from 'react'
import BaseModal from './index'

const ConfirmModal = ({
    title,
    description,
    isVisible,
    onClose,
    onAccept,
    showDetails
}) => {
    return (
        <BaseModal
            title={title}
            description={description}
            isVisible={isVisible}
            onClose={onClose}
            showDetails={showDetails}>
            <div className="flex flex-col items-center">
                <button
                    className="bg-red font-bold text-white rounded-xl p-3 w-full"
                    onClick={onAccept}>
                    Confirmar
                </button>
                <button
                    className="text-white font-semibold mt-6"
                    onClick={onClose}>
                    Regresar
                </button>
            </div>
        </BaseModal>
    )
}

export default ConfirmModal
