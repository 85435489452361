import React, { Fragment, useContext, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { UserContext } from 'context/UserContext'
import KilometersItem from 'components/KilometersItem'
import TachometerSVG from 'assets/tachometer.svg'
import SkeletonItem from 'components/SkeletonItem'

export default function HomeKilometers({ kilometers, loading }) {
    const navigate = useNavigate()
    const { state: userState } = useContext(UserContext)

    const [currentMonth] = useState(dayjs().format('MMMM'))

    const onKilometers = () => {
        return navigate(`/kilometers/${userState.user._id}`)
    }

    const onKilometersDetails = (kilometersID) => {
        return navigate(
            `/kilometers/${userState.user._id}/update/${kilometersID}`
        )
    }

    return (
        <Fragment>
            <div>
                <div className="font-semibold flex justify-between items-center mb-6">
                    <div className="text-xs uppercase">
                        KILÓMETROS MES {currentMonth}
                    </div>
                    <div
                        className="text-primary text-sm"
                        onClick={onKilometers}>
                        Ver todos
                    </div>
                </div>

                <div className="flex flex-col gap-6">
                    {loading ? (
                        [...Array(5).keys()].map((index) => (
                            <SkeletonItem key={index} />
                        ))
                    ) : (
                        <Fragment>
                            {kilometers.length ? (
                                <Fragment>
                                    {kilometers.map((item) => (
                                        <KilometersItem
                                            key={item._id}
                                            data={item}
                                            showRemove={false}
                                            onClick={() =>
                                                onKilometersDetails(item._id)
                                            }
                                        />
                                    ))}
                                </Fragment>
                            ) : (
                                <div className="bg-dark-500 rounded-lg p-4 flex items-start">
                                    <img src={TachometerSVG} className="mr-4" />
                                    <div>
                                        <div className="text-white text-sm">
                                            Aún no tienes kilómetros registrados
                                        </div>
                                        <div className="text-text text-xs">
                                            Recuerda registrar tus kilómetros
                                            después de cada rodada.
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Fragment>
                    )}
                </div>
            </div>
        </Fragment>
    )
}
