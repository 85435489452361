import React, { Fragment, useContext, useEffect, useState } from 'react'
import { MembersAPI } from 'api'
import { UserContext } from 'context/UserContext'
import { useNavigate } from 'react-router-dom'
import Header from 'components/Header'

import MemberItem from 'components/MemberItem'
import { USER_ROLES } from 'constants'
import SkeletonItem from 'components/SkeletonItem'
import { capitalizeText } from 'utils'

export default function Members() {
    const navigate = useNavigate()
    const { state: userState } = useContext(UserContext)
    const [members, setMembers] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        onGetMembers()
    }, [])

    const onGetMembers = async () => {
        const { data } = await MembersAPI.get()
        setMembers(data.users)
        setLoading(false)
    }

    const onBackward = () => {
        navigate('/home')
    }

    const onAction = () => {
        navigate('/invite')
    }

    const onMemberDetails = (payload) => {
        navigate(`/members/profile/${payload._id}`)
    }

    return (
        <Fragment>
            <Header
                className="mb-12"
                onBackward={onBackward}
                onAction={onAction}
                showAction={[
                    USER_ROLES.VICEPRESIDENTE,
                    USER_ROLES.PRESIDENTE,
                    USER_ROLES.ADMIN
                ].includes(userState.user.role)}
                title="Miembros"
                description={`Capítulo ${capitalizeText(
                    userState.user.chapter
                )}`}
            />

            <div className="flex flex-col gap-6">
                {loading ? (
                    [...Array(10).keys()].map((index) => (
                        <SkeletonItem key={index} />
                    ))
                ) : (
                    <Fragment>
                        {members.map((item) => (
                            <MemberItem
                                key={item._id}
                                data={item}
                                onClick={onMemberDetails}
                                isMe={userState.user._id === item._id}
                            />
                        ))}
                    </Fragment>
                )}
            </div>
        </Fragment>
    )
}
