import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import {
    EnvelopeIcon,
    LockClosedIcon,
    EyeIcon
} from '@heroicons/react/24/outline'
import { MembersAPI } from 'api'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Header from 'components/Header'
import { PatternFormat } from 'react-number-format'
import { UserContext } from 'context/UserContext'
import { emitter } from 'utils'

export default function MembersInformation() {
    const formRef = useRef()
    const navigate = useNavigate()
    const { _id: memberID } = useParams()
    const { setState: setUserState, isMyProfile } = useContext(UserContext)
    const [isMe] = useState(isMyProfile(memberID))
    const [member, setMember] = useState({})

    useEffect(() => {
        onGetMemberDetails()
    }, [])

    const onGetMemberDetails = async () => {
        const { data } = await MembersAPI.getOne(memberID)
        const {
            name,
            lastName,
            email,
            phone,
            bloodType,
            emergencyName,
            emergenyPhone
        } = data.user
        formRef.current.setValues({
            ...formRef.current.values,
            name,
            lastName,
            email,
            phone: phone ?? '',
            emergenyPhone: emergenyPhone ?? '',
            emergencyName: emergencyName ?? '',
            bloodType: bloodType ?? '',
            password: '',
            confirmPassword: ''
        })
    }

    const onSubmit = async ({
        name,
        lastName,
        phone,
        bloodType,
        emergencyName,
        emergenyPhone,
        password
    }) => {
        const { data } = await MembersAPI.update({
            name,
            lastName,
            phone,
            bloodType,
            emergencyName,
            emergenyPhone,
            password: password === '' ? undefined : password
        })
        if (data.success) {
            /**
             * Actualizar localStorage si el usuario
             * que se actualiza es el propio...
             */
            if (isMe) {
                const { name, lastName } = data.user
                setUserState((prevState) => ({
                    ...prevState,
                    user: {
                        ...prevState.user,
                        name,
                        lastName
                    }
                }))
            }

            emitter.emit('onOpenNotification', {
                type: 'success',
                title: 'Información actualizada',
                message: `Tu información personal se actualizó correctamente`
            })
        }
    }

    const onBackward = () => {
        navigate(-1)
    }

    const onShowPassword = ($event) => {
        const input = $event.currentTarget.nextSibling

        if (input.type === 'password') {
            input.type = 'text'
        } else {
            input.type = 'password'
        }
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Este campo es requerido'),
        lastName: Yup.string().required('Este campo es requerido'),
        phone: Yup.string().test(
            'phone',
            'Escribe un teléfono válido',
            (value) => {
                if (!value) {
                    return true
                }

                const formatoTelefono = /^\(\d{3}\) \d{3}-\d{4}$/
                return formatoTelefono?.test(value)
            }
        ),
        emergencyName: Yup.string().required('Este campo es requerido'),
        emergenyPhone: Yup.string()
            .required('Este campo es requerido')
            .test('emergenyPhone', 'Escribe un teléfono válido', (value) => {
                if (!value) {
                    return true
                }

                const formatoTelefono = /^\(\d{3}\) \d{3}-\d{4}$/
                return formatoTelefono?.test(value)
            }),
        bloodType: Yup.string().required('Este campo es requerido'),
        email: Yup.string()
            .required('Este campo es requerido')
            .email('Correo electrónico inválido'),
        password: Yup.string().matches(
            /^(|.{8,})$/,
            'Debe tener al menos 8 caracteres'
        ),
        confirmPassword: Yup.string().test(
            'testPassword',
            'La contraseña debe coincidir',
            (value, context) => {
                const password = context.parent.password
                if (!password) {
                    return true
                }

                if (password === value) {
                    return true
                }
            }
        )
    })

    const initialValues = {
        name: '',
        lastName: '',
        email: '',
        phone: '',
        emergencyPhone: '',
        emergencyName: '',
        bloodType: '',
        password: '',
        confirmPassword: ''
    }

    const roles = ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-']

    return (
        <Fragment>
            <Header className="mb-12" onBackward={onBackward} />

            <div className="mb-10">
                <h1 className="font-bold mb-2 text-2xl text-white">
                    Información personal
                </h1>
                {isMe && (
                    <p>
                        Completa tu perfil, esto ayudará a los miembros de tú
                        capítulo a saber tu información personal en caso de una
                        emergencia.
                    </p>
                )}
            </div>

            <Formik
                onSubmit={onSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
                innerRef={formRef}>
                {({ errors, touched }) => (
                    <Form className="flex flex-col grow" autoComplete="off">
                        <div className="mb-auto flex flex-col gap-6">
                            <div className="flex gap-6">
                                <div className="w-1/2">
                                    <label
                                        htmlFor="name"
                                        className="inline-block text-sm mb-2 text-white">
                                        Nombre
                                    </label>
                                    <Field
                                        disabled={!isMe}
                                        autoComplete="off"
                                        id="name"
                                        name="name"
                                        className={`w-full rounded-lg py-2 px-4 border ${
                                            errors.name && touched.name
                                                ? 'border-red focus:border-red bg-red/5 placeholder:text-red/50'
                                                : 'border-transparent focus:border-primary bg-dark-500 placeholder:text-text placeholder:text-sm'
                                        }`}
                                    />
                                    <ErrorMessage
                                        name="name"
                                        component="small"
                                        className="text-red inline-block text-xs w-full mt-2"
                                    />
                                </div>

                                <div className="w-1/2">
                                    <label
                                        htmlFor="lastName"
                                        className="inline-block text-sm mb-2 text-white">
                                        Apellido
                                    </label>
                                    <Field
                                        disabled={!isMe}
                                        autoComplete="off"
                                        id="lastName"
                                        name="lastName"
                                        className={`w-full rounded-lg py-2 px-4 border ${
                                            errors.lastName && touched.lastName
                                                ? 'border-red focus:border-red bg-red/5 placeholder:text-red/50'
                                                : 'border-transparent focus:border-primary bg-dark-500 placeholder:text-text placeholder:text-sm'
                                        }`}
                                    />
                                    <ErrorMessage
                                        name="lastName"
                                        component="small"
                                        className="text-red inline-block text-xs w-full mt-2"
                                    />
                                </div>
                            </div>

                            <div>
                                <label
                                    htmlFor="phone"
                                    className="inline-block text-sm mb-2 text-white">
                                    Teléfono
                                </label>
                                <Field name="phone">
                                    {({ field, ...props }) => (
                                        <PatternFormat
                                            {...field}
                                            {...props}
                                            disabled={!isMe}
                                            autoComplete="off"
                                            format="(###) ###-####"
                                            mask=""
                                            placeholder="(000) 000-0000"
                                            className={`w-full rounded-lg py-2 px-4 border ${
                                                errors.phone && touched.phone
                                                    ? 'border-red focus:border-red bg-red/5 placeholder:text-red/50'
                                                    : 'border-transparent focus:border-primary bg-dark-500 placeholder:text-text placeholder:text-sm'
                                            }`}
                                        />
                                    )}
                                </Field>
                                <ErrorMessage
                                    name="phone"
                                    component="small"
                                    className="text-red inline-block text-xs w-full mt-2"
                                />
                            </div>

                            <div>
                                <label
                                    htmlFor="bloodType"
                                    className="inline-block text-sm mb-2 text-white">
                                    Tipo de sangre
                                </label>
                                <Field
                                    disabled={!isMe}
                                    autoComplete="off"
                                    id="bloodType"
                                    name="bloodType"
                                    as="select"
                                    className={`w-full rounded-lg py-2 px-4 border ${
                                        errors.bloodType && touched.bloodType
                                            ? 'border-red focus:border-red bg-red/5 placeholder:text-red/50'
                                            : 'border-transparent focus:border-primary bg-dark-500 placeholder:text-text placeholder:text-sm'
                                    }`}>
                                    <option defaultValue hidden></option>
                                    {roles.map((item) => (
                                        <option key={item}>{item}</option>
                                    ))}
                                </Field>
                                <ErrorMessage
                                    name="bloodType"
                                    component="small"
                                    className="text-red inline-block text-xs w-full mt-2"
                                />
                            </div>

                            <div className="h-0.5 bg-dark-400"></div>

                            <div>
                                <label
                                    htmlFor="emergencyName"
                                    className="inline-block text-sm mb-2 text-white">
                                    Nombre emergencia
                                </label>
                                <Field
                                    disabled={!isMe}
                                    autoComplete="off"
                                    id="emergencyName"
                                    name="emergencyName"
                                    className={`w-full rounded-lg py-2 px-4 border ${
                                        errors.emergencyName &&
                                        touched.emergencyName
                                            ? 'border-red focus:border-red bg-red/5 placeholder:text-red/50'
                                            : 'border-transparent focus:border-primary bg-dark-500 placeholder:text-text placeholder:text-sm'
                                    }`}
                                />
                                <ErrorMessage
                                    name="emergencyName"
                                    component="small"
                                    className="text-red inline-block text-xs w-full mt-2"
                                />
                            </div>

                            <div>
                                <label
                                    htmlFor="emergenyPhone"
                                    className="inline-block text-sm mb-2 text-white">
                                    Teléfono de emergencia
                                </label>
                                <Field name="emergenyPhone">
                                    {({ field, ...props }) => (
                                        <PatternFormat
                                            {...field}
                                            {...props}
                                            disabled={!isMe}
                                            autoComplete="off"
                                            format="(###) ###-####"
                                            mask=""
                                            placeholder="(000) 000-0000"
                                            className={`w-full rounded-lg py-2 px-4 border ${
                                                errors.emergenyPhone &&
                                                touched.emergenyPhone
                                                    ? 'border-red focus:border-red bg-red/5 placeholder:text-red/50'
                                                    : 'border-transparent focus:border-primary bg-dark-500 placeholder:text-text placeholder:text-sm'
                                            }`}
                                        />
                                    )}
                                </Field>
                                <ErrorMessage
                                    name="emergenyPhone"
                                    component="small"
                                    className="text-red inline-block text-xs w-full mt-2"
                                />
                            </div>

                            {isMe && (
                                <Fragment>
                                    <div className="h-0.5 bg-dark-400"></div>

                                    <div>
                                        <label
                                            htmlFor="email"
                                            className="inline-block text-sm mb-2 text-white">
                                            Correo electrónico
                                        </label>
                                        <div className="relative">
                                            <EnvelopeIcon
                                                className={`w-5 absolute top-1/2 -translate-y-1/2 left-4 ${
                                                    errors.email &&
                                                    touched.email
                                                        ? 'text-red'
                                                        : 'text-text'
                                                }`}
                                            />
                                            <Field
                                                disabled
                                                id="email"
                                                type="email"
                                                name="email"
                                                placeholder="Escribe tu nombre de usuario"
                                                className={`w-full rounded-lg py-2 px-4 border pl-12 ${
                                                    errors.email &&
                                                    touched.email
                                                        ? 'border-red focus:border-red bg-red/5 placeholder:text-red/50'
                                                        : 'border-transparent focus:border-primary bg-dark-500 placeholder:text-text placeholder:text-sm'
                                                }`}
                                            />
                                        </div>
                                        <ErrorMessage
                                            name="email"
                                            component="small"
                                            className="text-red inline-block text-xs w-full mt-2"
                                        />
                                    </div>

                                    <div>
                                        <label
                                            htmlFor="password"
                                            className="inline-block text-sm mb-2 text-white">
                                            Contraseña
                                        </label>
                                        <div className="relative">
                                            <LockClosedIcon
                                                className={`w-5 absolute top-1/2 -translate-y-1/2 left-4 ${
                                                    errors.password &&
                                                    touched.password
                                                        ? 'text-red'
                                                        : 'text-text'
                                                }`}
                                            />
                                            <EyeIcon
                                                className={`w-5 absolute top-1/2 -translate-y-1/2 right-4 ${
                                                    errors.password &&
                                                    touched.password
                                                        ? 'text-red'
                                                        : 'text-text'
                                                }`}
                                                onClick={onShowPassword}
                                            />
                                            <Field
                                                id="password"
                                                name="password"
                                                placeholder="Contraseña"
                                                type="password"
                                                className={`w-full rounded-lg py-2 border px-12 ${
                                                    errors.password &&
                                                    touched.password
                                                        ? 'border-red focus:border-red bg-red/5 placeholder:text-red/50'
                                                        : 'border-transparent focus:border-primary bg-dark-500 placeholder:text-text placeholder:text-sm'
                                                }`}
                                            />
                                        </div>
                                        <ErrorMessage
                                            name="password"
                                            component="small"
                                            className="text-red inline-block text-xs w-full mt-2"
                                        />
                                    </div>

                                    <div>
                                        <label
                                            htmlFor="confirmPassword"
                                            className="inline-block text-sm mb-2 text-white">
                                            Confirmar contraseña
                                        </label>
                                        <div className="relative">
                                            <LockClosedIcon
                                                className={`w-5 absolute top-1/2 -translate-y-1/2 left-4 ${
                                                    errors.confirmPassword &&
                                                    touched.confirmPassword
                                                        ? 'text-red'
                                                        : 'text-text'
                                                }`}
                                            />
                                            <EyeIcon
                                                className={`w-5 absolute top-1/2 -translate-y-1/2 right-4 ${
                                                    errors.confirmPassword &&
                                                    touched.confirmPassword
                                                        ? 'text-red'
                                                        : 'text-text'
                                                }`}
                                                onClick={onShowPassword}
                                            />
                                            <Field
                                                id="confirmPassword"
                                                name="confirmPassword"
                                                placeholder="Contraseña"
                                                type="password"
                                                className={`w-full rounded-lg py-2 border px-12 ${
                                                    errors.confirmPassword &&
                                                    touched.confirmPassword
                                                        ? 'border-red focus:border-red bg-red/5 placeholder:text-red/50'
                                                        : 'border-transparent focus:border-primary bg-dark-500 placeholder:text-text placeholder:text-sm'
                                                }`}
                                            />
                                        </div>
                                        <ErrorMessage
                                            name="confirmPassword"
                                            component="small"
                                            className="text-red inline-block text-xs w-full mt-2"
                                        />
                                    </div>
                                </Fragment>
                            )}
                        </div>
                        {isMe && (
                            <button
                                className="bg-primary font-bold text-white rounded-xl p-3 mt-10"
                                type="submit">
                                Actualizar información
                            </button>
                        )}
                    </Form>
                )}
            </Formik>
        </Fragment>
    )
}
