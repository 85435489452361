export default function EventItem({ data, onClick }) {
    return (
        <div
            onClick={() => onClick(data)}
            className={`rounded-lg flex items-center p-4 flex-auto bg-dark-500`}>
            <div className="overflow-x-hidden">
                <div className="text-white text-sm capitalize whitespace-nowrap overflow-x-hidden text-ellipsis">
                    {data.endingPoint}
                </div>
                <div className="text-text text-xs capitalize whitespace-nowrap overflow-x-hidden text-ellipsis">
                    {data.user.chapter} / {data.user.role}
                </div>
            </div>
        </div>
    )
}
