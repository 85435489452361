import api from 'services/api'

export const add = async (event) => {
    const response = await api.post('/events', event)

    return response
}

export const get = async (params) => {
    const response = await api.get('/events', {
        params
    })

    return response
}

export const getOne = async (eventID) => {
    const response = await api.get(`/events/${eventID}`)
    return response
}

export const join = async (eventID) => {
    const response = await api.put(`/events/join/${eventID}`)
    return response
}

export const abandon = async (eventID) => {
    const response = await api.delete(`/events/abandon/${eventID}`)
    return response
}

export const remove = async (eventID) => {
    const response = await api.delete(`/events/${eventID}`)
    return response
}

export default {
    add,
    get,
    getOne,
    join,
    abandon,
    remove
}
