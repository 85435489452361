import React, { useRef, useState, useContext, useEffect, Fragment } from 'react'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { MapPinIcon } from '@heroicons/react/24/outline'
import { NumericFormat } from 'react-number-format'
import { EventsAPI, KilometersAPI } from 'api'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import Header from 'components/Header'
import Datepicker from 'components/Datepicker'
import { emitter, numberWithoutComma } from 'utils'
import { UserContext } from 'context/UserContext'
import PostItSVG from 'assets/post_it.svg'
import GooglePlacesInput from 'components/Input/GooglePlaces'

export default function EventsAdd() {
    const formRef = useRef()
    const navigate = useNavigate()
    const { memberID } = useParams()
    const { state, isMyProfile } = useContext(UserContext)

    const [currentMonth] = useState(() => dayjs().format('MMMM'))
    const [isMe] = useState(isMyProfile(memberID))

    useEffect(() => {}, [])

    const onAdd = async ({ endingPoint, open, friends, description }) => {
        const { data } = await EventsAPI.add({
            endingPoint,
            open,
            friends,
            description
        })

        if (data.success) {
            emitter.emit('onOpenNotification', {
                type: 'success',
                title: 'Evento creado',
                message: `Tu evento se creó correctamente`
            })

            onBackward()
        }
    }

    const onBackward = () => {
        navigate(-1)
    }

    const initialValues = {
        endingPoint: '',
        open: false,
        friends: false,
        description: ''
    }

    const validationSchema = Yup.object().shape({
        endingPoint: Yup.string().required('Este campo es requerido'),
        open: Yup.string().required('Este campo es requerido'),
        friends: Yup.string().required('Este campo es requerido'),
        description: Yup.string().required('Este campo es requerido')
    })

    return (
        <Fragment>
            <Header className="mb-12" onBackward={onBackward} />

            <div className="mb-10">
                <h1 className="font-bold mb-2 text-2xl text-white">
                    Crear evento
                </h1>
                <p>
                    Crear un evento para que otros miembros puedan unirse a tus
                    rodadas.
                </p>
            </div>

            <Formik
                initialValues={initialValues}
                onSubmit={onAdd}
                validationSchema={validationSchema}
                innerRef={formRef}>
                {({ errors, touched }) => (
                    <Form className="flex flex-col grow" autoComplete="off">
                        <div className="mb-auto flex flex-col gap-6">
                            <div>
                                <label
                                    htmlFor="endingPoint"
                                    className="inline-block text-sm mb-2 text-white">
                                    ¿Cuál es el lugar de destino?
                                </label>
                                <div className="relative">
                                    <MapPinIcon
                                        className={`w-5 absolute top-1/2 -translate-y-1/2 left-4 z-10 ${
                                            errors.endingPoint &&
                                            touched.endingPoint
                                                ? 'text-red'
                                                : 'text-text'
                                        }`}
                                    />
                                    <Field
                                        name="endingPoint"
                                        component={GooglePlacesInput}
                                        className={`w-full rounded-lg py-2 px-4 border pl-12 ${
                                            errors.endingPoint &&
                                            touched.endingPoint
                                                ? 'border-red focus:border-red bg-red/5 placeholder:text-red/50'
                                                : 'border-transparent focus:border-primary bg-dark-500 placeholder:text-text placeholder:text-sm'
                                        }`}
                                    />
                                </div>
                                <ErrorMessage
                                    name="endingPoint"
                                    component="small"
                                    className="text-red inline-block text-xs w-full mt-2"
                                />
                            </div>

                            <div>
                                <label
                                    htmlFor="open"
                                    className="inline-block text-sm mb-2 text-white">
                                    Evento abierto a:
                                </label>
                                <Field
                                    id="open"
                                    name="open"
                                    as="select"
                                    className={`w-full rounded-lg py-2 px-4 border ${
                                        errors.open && touched.open
                                            ? 'border-red focus:border-red bg-red/5 placeholder:text-red/50'
                                            : 'border-transparent focus:border-primary bg-dark-500 placeholder:text-text placeholder:text-sm'
                                    }`}>
                                    <option value={false}>
                                        Solo miembros de capítulo{' '}
                                        {state.user.chapter}
                                    </option>
                                    <option value={true}>
                                        Todos los miembros del club
                                    </option>
                                </Field>
                                <ErrorMessage
                                    name="open"
                                    component="small"
                                    className="text-red inline-block text-xs w-full mt-2"
                                />
                            </div>

                            <div>
                                <label
                                    htmlFor="friends"
                                    className="inline-block text-sm mb-2 text-white">
                                    ¿Se puede invitar amigos?
                                </label>
                                <Field
                                    id="friends"
                                    name="friends"
                                    as="select"
                                    className={`w-full rounded-lg py-2 px-4 border ${
                                        errors.friends && touched.friends
                                            ? 'border-red focus:border-red bg-red/5 placeholder:text-red/50'
                                            : 'border-transparent focus:border-primary bg-dark-500 placeholder:text-text placeholder:text-sm'
                                    }`}>
                                    <option value={false}>
                                        No, solo coyotes
                                    </option>
                                    <option value={true}>
                                        Si, amigos del club
                                    </option>
                                </Field>
                                <ErrorMessage
                                    name="friends"
                                    component="small"
                                    className="text-red inline-block text-xs w-full mt-2"
                                />
                            </div>

                            <div>
                                <label
                                    htmlFor="description"
                                    className="inline-block text-sm mb-2 text-white">
                                    Detalles del evento
                                </label>
                                <Field
                                    id="description"
                                    name="description"
                                    as="textarea"
                                    rows="3"
                                    className={`w-full rounded-lg py-2 px-4 border ${
                                        errors.description &&
                                        touched.description
                                            ? 'border-red focus:border-red bg-red/5 placeholder:text-red/50'
                                            : 'border-transparent focus:border-primary bg-dark-500 placeholder:text-text placeholder:text-sm'
                                    }`}
                                />
                                <ErrorMessage
                                    name="description"
                                    component="small"
                                    className="text-red inline-block text-xs w-full mt-2"
                                />
                            </div>
                        </div>

                        <button
                            className="bg-primary font-bold text-white rounded-xl p-3 mt-10"
                            type="submit">
                            Crear evento
                        </button>
                    </Form>
                )}
            </Formik>
        </Fragment>
    )
}
