import React, { useContext, useRef, Fragment } from 'react'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { NumericFormat } from 'react-number-format'
import { ContributionsAPI } from 'api'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import Header from 'components/Header'
import Datepicker from 'components/Datepicker'
import { emitter, numberWithoutComma } from 'utils'
import { UserContext } from 'context/UserContext'
import { useEffect } from 'react'
import { USER_ROLES } from 'constants'

export default function ContributionsAdd() {
    const navigate = useNavigate()
    const { memberID } = useParams()
    const formRef = useRef()
    const { state: userState } = useContext(UserContext)

    useEffect(() => {
        if (
            ![USER_ROLES.SECRETARIO, USER_ROLES.ADMIN].includes(
                userState.user.role
            )
        ) {
            navigate('/home')
        }
    }, [])

    const onAdd = async ({ concept, date, total }) => {
        const { data } = await ContributionsAPI.add(memberID, {
            concept,
            date,
            total: numberWithoutComma(total)
        })

        if (data.success) {
            emitter.emit('onOpenNotification', {
                type: 'success',
                title: 'Aportación agregada',
                message: `La aportación fue agregada correctamente`
            })

            onBackward()
        }
    }

    const onBackward = () => {
        navigate(-1)
    }

    const initialValues = {
        concept: '',
        date: dayjs().toDate(),
        total: ''
    }

    const validationSchema = Yup.object().shape({
        concept: Yup.string().required('Este campo es requerido'),
        date: Yup.string().required('Este campo es requerido'),
        total: Yup.string()
            .required('Este campo es requerido')
            .test(
                'min-amount',
                'Tu aportación debe ser mayor a 0',
                (amount) => {
                    if (amount) {
                        let amountTotal = numberWithoutComma(amount)
                        if (amountTotal > 0) {
                            return true
                        }
                    }
                }
            )
    })

    return (
        <Fragment>
            <Header className="mb-12" onBackward={onBackward} />

            <div className="mb-10">
                <h1 className="font-bold mb-2 text-2xl text-white">
                    Agregar aportación
                </h1>
                <p>
                    Registra las contribuciones por cada miembro para llevar un
                    seguimiento más preciso y eficiente.
                </p>
            </div>

            <Formik
                initialValues={initialValues}
                onSubmit={onAdd}
                validationSchema={validationSchema}
                innerRef={formRef}>
                {({ errors, touched }) => (
                    <Form className="flex flex-col grow" autoComplete="off">
                        <div className="mb-auto flex flex-col gap-6">
                            <div>
                                <label
                                    htmlFor="concept"
                                    className="inline-block text-sm mb-2 text-white">
                                    Concepto
                                </label>
                                <Field
                                    id="concept"
                                    name="concept"
                                    className={`w-full rounded-lg py-2 px-4 border ${
                                        errors.concept && touched.concept
                                            ? 'border-red focus:border-red bg-red/5 placeholder:text-red/50'
                                            : 'border-transparent focus:border-primary bg-dark-500 placeholder:text-text placeholder:text-sm'
                                    }`}
                                />
                                <ErrorMessage
                                    name="concept"
                                    component="small"
                                    className="text-red inline-block text-xs w-full mt-2"
                                />
                            </div>

                            <div>
                                <label
                                    htmlFor="date"
                                    className="inline-block text-sm mb-2 text-white">
                                    Fecha de aportación
                                </label>
                                <Field name="date" component={Datepicker} />
                                <ErrorMessage
                                    name="date"
                                    component="small"
                                    className="text-red inline-block text-xs w-full mt-2"
                                />
                            </div>

                            <div>
                                <label
                                    htmlFor="total"
                                    className="inline-block text-sm mb-2 text-white">
                                    Monto
                                </label>
                                <Field name="total" id="total">
                                    {({ field, ...props }) => (
                                        <NumericFormat
                                            {...field}
                                            {...props}
                                            id="total"
                                            thousandSeparator=","
                                            decimalScale={2}
                                            placeholder="0.00"
                                            autoComplete="off"
                                            className={`w-full rounded-lg py-2 px-4 border pr-16 ${
                                                errors.total && touched.total
                                                    ? 'border-red focus:border-red bg-red/5 placeholder:text-red/50'
                                                    : 'border-transparent focus:border-primary bg-dark-500 placeholder:text-text placeholder:text-sm'
                                            }`}
                                        />
                                    )}
                                </Field>
                                <ErrorMessage
                                    name="total"
                                    component="small"
                                    className="text-red inline-block text-xs w-full mt-2"
                                />
                            </div>
                        </div>

                        <button
                            className="bg-primary font-bold text-white rounded-xl p-3 mt-10"
                            type="submit">
                            Agregar aportación
                        </button>
                    </Form>
                )}
            </Formik>
        </Fragment>
    )
}
