import React, { useEffect, useState, useContext, Fragment } from 'react'
import { KilometersAPI } from 'api'
import { useNavigate, useParams } from 'react-router-dom'
import Header from 'components/Header'

import KilometersItem from 'components/KilometersItem'
import SkeletonItem from 'components/SkeletonItem'
import { UserContext } from 'context/UserContext'
import TachometerSVG from 'assets/tachometer.svg'
import { commaNumber, emitter } from 'utils'

export default function Kilometers() {
    const navigate = useNavigate()
    const { memberID } = useParams()
    const { isMyProfile } = useContext(UserContext)

    const [loading, setLoading] = useState(true)
    const [kilometers, setKilometers] = useState([])
    const [yearKilometers, setYearKilometers] = useState({})
    const [isMe] = useState(isMyProfile(memberID))

    useEffect(() => {
        onGetKilometers()
    }, [])

    const onGetKilometers = async () => {
        const { data } = await KilometersAPI.get(memberID)
        const yearKilometers = groupByYear(data.kilometers)

        setYearKilometers(yearKilometers)
        setKilometers(data.kilometers)
        setLoading(false)
    }

    const calculateTotalKilometers = (array) => {
        return array.reduce((previousValue, currentValue) => {
            return previousValue + currentValue.total
        }, 0)
    }

    const groupByYear = (array) => {
        return array.reduce((acc, obj) => {
            const year = new Date(obj.date).getFullYear()
            acc[year] = acc[year] || []
            acc[year].push(obj)
            return acc
        }, {})
    }

    const onBackward = () => {
        navigate(-1)
    }

    const onAction = () => {
        navigate('add')
    }

    const onUpdateKilometers = (payload) => {
        navigate(`update/${payload._id}`)
    }

    const onRemoveKilometers = async (payload, year) => {
        const { data } = await KilometersAPI.remove(payload._id)

        if (data.success) {
            const newKilometers = [...yearKilometers[year]]
            const deletedIndex = newKilometers.findIndex(
                (item) => item._id === payload._id
            )

            newKilometers.splice(deletedIndex, 1)

            const updatedPayload = {
                ...yearKilometers,
                [year]: newKilometers
            }

            if (newKilometers.length === 0) {
                delete updatedPayload[year]
            }

            setYearKilometers(updatedPayload)

            emitter.emit('onOpenNotification', {
                type: 'success',
                title: 'Kilómetros eliminados',
                message: `Tus kilómetros fueron eliminados correctamente`
            })
        }
    }

    return (
        <Fragment>
            <Header
                className="mb-12"
                onBackward={onBackward}
                onAction={onAction}
                showAction={isMe}
                title={`${commaNumber(
                    calculateTotalKilometers(kilometers)
                )} kms`}
                description="Total kilómetros recorridos"
            />

            <div className="flex flex-col gap-10">
                {loading ? (
                    [...Array(10).keys()].map((index) => (
                        <SkeletonItem key={index} />
                    ))
                ) : (
                    <Fragment>
                        {kilometers.length ? (
                            <Fragment>
                                {Object.keys(yearKilometers)
                                    .reverse()
                                    .map((year) => (
                                        <div
                                            key={year}
                                            className="flex flex-col gap-6">
                                            <div className="flex justify-between text-xs uppercase font-semibold">
                                                <div>Kilómetros {year}</div>
                                                <div>
                                                    {`${commaNumber(
                                                        calculateTotalKilometers(
                                                            yearKilometers[year]
                                                        )
                                                    )} kms`}
                                                </div>
                                            </div>
                                            {yearKilometers[year].map(
                                                (item) => (
                                                    <KilometersItem
                                                        key={item._id}
                                                        data={item}
                                                        onClick={
                                                            onUpdateKilometers
                                                        }
                                                        onRemove={() =>
                                                            onRemoveKilometers(
                                                                item,
                                                                year
                                                            )
                                                        }
                                                        showRemove={isMe}
                                                    />
                                                )
                                            )}
                                        </div>
                                    ))}
                            </Fragment>
                        ) : (
                            <div className="text-center flex flex-col items-center mt-20">
                                <img
                                    src={TachometerSVG}
                                    className="w-16 mb-4"
                                />
                                <div>
                                    <div className="text-white mb-2">
                                        Aún no hay kilómetros registrados
                                    </div>
                                    <div className="text-text text-sm">
                                        El historial de los kilómetros rodados
                                        aparecerá aquí. Es deber de cada miembro
                                        registrar sus propios kilómetros rodados
                                    </div>
                                </div>
                            </div>
                        )}
                    </Fragment>
                )}
            </div>
        </Fragment>
    )
}
