import React, { Fragment, useEffect, useState } from 'react'
import { MembersAPI } from 'api'
import { useNavigate, useParams } from 'react-router-dom'
import Header from 'components/Header'

import MemberItem from 'components/MemberItem'
import SkeletonItem from 'components/SkeletonItem'
import { capitalizeText } from 'utils'

export default function Members() {
    const navigate = useNavigate()
    const { chapterID } = useParams()

    const [members, setMembers] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        onGetMembers()
    }, [])

    const onGetMembers = async () => {
        const { data } = await MembersAPI.get({
            chapter: chapterID
        })

        setMembers(data.users)
        setLoading(false)
    }

    const onBackward = () => {
        navigate(-1)
    }

    const onMemberDetails = (payload) => {
        navigate(`/members/profile/${payload._id}`)
    }

    return (
        <Fragment>
            <Header
                className="mb-12"
                onBackward={onBackward}
                title="Miembros"
                description={`Capítulo ${capitalizeText(chapterID)}`}
            />

            <div className="flex flex-col gap-6">
                {loading ? (
                    [...Array(10).keys()].map((index) => (
                        <SkeletonItem key={index} />
                    ))
                ) : (
                    <Fragment>
                        {members.map((item) => (
                            <MemberItem
                                key={item._id}
                                data={item}
                                onClick={onMemberDetails}
                            />
                        ))}
                    </Fragment>
                )}
            </div>
        </Fragment>
    )
}
