import React, { Fragment, useContext, useEffect, useState } from 'react'
import { ChaptersAPI } from 'api'
import { UserContext } from 'context/UserContext'
import { useNavigate } from 'react-router-dom'
import Header from 'components/Header'
import { USER_ROLES } from 'constants'
import SkeletonItem from 'components/SkeletonItem'
import ChapterItem from 'components/ChaptersItem'

export default function Chapters() {
    const navigate = useNavigate()
    const { state: userState } = useContext(UserContext)
    const [chapters, setChapters] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (
            ![USER_ROLES.TLATOANI, USER_ROLES.ADMIN].includes(
                userState.user.role
            )
        ) {
            return navigate('/home')
        }

        onGetChapters()
    }, [])

    const onGetChapters = async () => {
        const { data } = await ChaptersAPI.get()
        setChapters(data.chapters)
        setLoading(false)
    }

    const onBackward = () => {
        navigate('/home')
    }

    const onChapterDetails = (data) => {
        navigate(`/chapters/${data.chapter}`)
    }

    const onInvite = () => {
        navigate('/invite')
    }

    return (
        <Fragment>
            <Header
                className="mb-12"
                onBackward={onBackward}
                onAction={onInvite}
                showAction={true}
                title="Capítulos"
                description="Todos los capítulos HuehueCoyotl"
            />

            <div className="flex flex-col gap-6">
                {loading ? (
                    [...Array(10).keys()].map((index) => (
                        <SkeletonItem key={index} />
                    ))
                ) : (
                    <Fragment>
                        {chapters.map((item) => (
                            <ChapterItem
                                key={item.chapter}
                                data={item}
                                onClick={onChapterDetails}
                            />
                        ))}
                    </Fragment>
                )}
            </div>
        </Fragment>
    )
}
