export const LOCAL_USER_STORAGE = 'UserContext'

export const USER_ROLES = {
    PROSPECTO: 'prospecto',
    MIEMBRO: 'miembro',
    SECRETARIO: 'secretario',
    SARGENTO: 'sargento',
    CAPITAN: 'capitán',
    VICEPRESIDENTE: 'vicepresidente',
    PRESIDENTE: 'presidente',
    TLATOANI: 'tlatoani',
    ADMIN: 'admin'
}

export const CHAPTERS = {
    NOMADA: 'nómada',
    ORIGINAL: 'original',
    TAMPICO: 'tampico',
    MONTERREY: 'monterrey',
    POSA_RICA: 'poza rica',
    VERACRUZ: 'veracruz',
    REYNOSA: 'reynosa',
    NACIONAL: 'nacional'
}
