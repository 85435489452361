import React from 'react'
import { Routes, Route } from 'react-router-dom'

import {
    IsLoggedMiddleware,
    IsLoggedOutMiddleware
} from './middlewares/authorization'

import Menu from 'views/Menu'
import NotFound from 'views/NotFound'
import Signin from 'views/Signin'
import Signup from 'views/Signup'
import Home from 'views/Home'
import Invite from 'views/Invite'
import Leadership from 'views/Leadership'

import Events from 'views/Events'
import EventsAdd from 'views/Events/Add'
import EventsDetails from 'views/Events/Details'

import Kilometers from 'views/Kilometers'
import KilometersAdd from 'views/Kilometers/Add'
import KilometersUpdate from 'views/Kilometers/Update'

import Contributions from 'views/Contributions'
import ContributionsAdd from 'views/Contributions/Add'
import ContributionsUpdate from 'views/Contributions/Update'

import Members from 'views/Members'
import MembersProfile from 'views/Members/Profile'
import MembersInformation from 'views/Members/Information'

import Chapters from 'views/Chapters'
import ChaptersMembers from 'views/Chapters/Members'

const AppRoutes = () => (
    <Routes>
        <Route path="*" element={<NotFound />} />

        <Route element={<IsLoggedOutMiddleware />}>
            <Route path="/" element={<Signin />} />
            <Route path="/signup" element={<Signup />} />
        </Route>

        <Route element={<IsLoggedMiddleware />}>
            <Route element={<Menu />}>
                <Route path="/home" element={<Home />} />
                <Route path="/invite" element={<Invite />} />
                <Route path="/leadership" element={<Leadership />} />

                <Route path="/members">
                    <Route path="" element={<Members />} />
                    <Route path="profile/:_id">
                        <Route path="" element={<MembersProfile />} />
                        <Route
                            path="information"
                            element={<MembersInformation />}
                        />
                    </Route>
                </Route>

                <Route path="/chapters">
                    <Route path="" element={<Chapters />} />
                    <Route path=":chapterID" element={<ChaptersMembers />} />
                </Route>

                <Route path="/events">
                    <Route path="" element={<Events />} />
                    <Route path="add" element={<EventsAdd />} />
                    <Route path=":eventID" element={<EventsDetails />} />
                </Route>

                <Route path="/kilometers/:memberID">
                    <Route path="" element={<Kilometers />} />
                    <Route path="add" element={<KilometersAdd />} />
                    <Route
                        path="update/:kilometersID"
                        element={<KilometersUpdate />}
                    />
                </Route>

                <Route path="/contributions/:memberID">
                    <Route path="" element={<Contributions />} />
                    <Route path="add" element={<ContributionsAdd />} />
                    <Route
                        path="update/:_id"
                        element={<ContributionsUpdate />}
                    />
                </Route>
            </Route>
        </Route>
    </Routes>
)

export default AppRoutes
