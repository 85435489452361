import { commaNumber, firstName } from 'utils'

export default function ChaptersItemCard({ data, onClick }) {
    return (
        <div
            onClick={onClick}
            className="shrink-0 grow-0 bg-dark-500 rounded-lg p-6 h-36 flex flex-col justify-center items-center text-center">
            <div className="text-secundary font-semibold">
                <div className="text-2xl mr-1">{data.members}</div>
                <div className="text-xs">Miembros</div>
            </div>
            <div className="text-white font-semibold uppercase mt-2 text-[10px]">
                Capítulo {data.chapter}
            </div>
        </div>
    )
}
