import api from 'services/api'

export const get = async (params) => {
    const response = await api.get('/users', {
        params
    })

    return response
}

export const getOne = async (memberID) => {
    const response = await api.get(`/users/${memberID}`)

    return response
}

export const update = async (user) => {
    const response = await api.put(`/users`, user)

    return response
}

export default {
    get,
    getOne,
    update
}
