import {
    ClipboardIcon,
    HomeIcon,
    CalendarIcon,
    UserIcon
} from '@heroicons/react/24/outline'
import { UserContext } from 'context/UserContext'
import { Fragment, useContext } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

export default function Menu() {
    const { state: userState } = useContext(UserContext)
    const location = useLocation()
    const navigate = useNavigate()

    const menuItems = [
        {
            icon: <HomeIcon className="w-5" />,
            pathname: '/home',
            label: 'Inicio',
            action: function () {
                navigate(this.pathname)
            }
        },
        {
            icon: <ClipboardIcon className="w-5" />,
            pathname: '/leadership',
            label: 'Leadership',
            action: function () {
                navigate(this.pathname)
            }
        },
        // {
        //     icon: <CalendarIcon className="w-5" />,
        //     pathname: `/events`,
        //     label: 'Eventos',
        //     action: function () {
        //         navigate(this.pathname)
        //     }
        // },
        {
            icon: <UserIcon className="w-5" />,
            pathname: `/members/profile/${userState.user._id}`,
            label: 'Perfil',
            action: function () {
                navigate(this.pathname)
            }
        }
    ]

    return (
        <Fragment>
            <div className="pb-[114px] flex flex-col px-6 py-10">
                <Outlet />
            </div>
            <div
                id="menu"
                className="z-10 bg-dark-500 py-4 fixed bottom-0 left-0 right-0 flex justify-between px-14 border-t-2 border-dark-400">
                {menuItems.map((menu) => (
                    <div
                        key={menu.pathname}
                        className={`flex h-10 w-10 rounded-lg items-center justify-center ${
                            location.pathname === menu.pathname
                                ? 'text-white bg-dark-400'
                                : 'text-text'
                        }`}
                        onClick={() => menu.action.call(menu)}>
                        {menu.icon}
                    </div>
                ))}
            </div>
        </Fragment>
    )
}
