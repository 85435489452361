import { ArrowLongLeftIcon } from '@heroicons/react/24/outline'
export default function BackButton({ className, onClick }) {
    return (
        <div
            onClick={onClick}
            className={`shrink-0 flex w-10 h-10 bg-dark-400 rounded-lg cursor-pointer ${className}`}>
            <ArrowLongLeftIcon className="w-5 m-auto text-white" />
        </div>
    )
}
