import { CHAPTERS, USER_ROLES } from 'constants'

export default (role, chapter) => {
    const { NOMADA } = CHAPTERS
    const {
        SECRETARIO,
        SARGENTO,
        CAPITAN,
        VICEPRESIDENTE,
        PRESIDENTE,
        TLATOANI,
        ADMIN,
        PROSPECTO,
        MIEMBRO
    } = USER_ROLES

    /**
     * Si es nómada o es miembro de la mesa directa
     * de algún capítulo...
     */
    if (
        chapter === NOMADA ||
        [
            SECRETARIO,
            SARGENTO,
            CAPITAN,
            VICEPRESIDENTE,
            PRESIDENTE,
            TLATOANI,
            ADMIN
        ].includes(role)
    ) {
        return 3500
    }

    if ([PROSPECTO, MIEMBRO].includes(role)) {
        return 2000
    }
}
