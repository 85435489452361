import anime from 'animejs'
import React, { useEffect } from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline'

export default function BaseModal({
    isVisible,
    onClose,
    title,
    description,
    children,
    showDetails = true,
    showClose = true
}) {
    useEffect(() => {
        if (isVisible) {
            document.body.style.overflow = 'hidden'
            fadeInAnimation()
        } else {
            document.body.style.overflow = 'auto'
        }
    }, [isVisible])

    useEffect(() => {
        return () => {
            document.body.style.overflow = 'auto'
        }
    }, [])

    const fadeInAnimation = () => {
        let container = document.getElementById('modal')

        container.style.overflow = 'hidden'

        anime
            .timeline({
                easing: 'easeOutExpo',
                duration: 500,
                complete() {
                    container.style.overflow = 'auto'
                }
            })
            .add({
                opacity: [0, 1],
                targets: container
            })
            .add(
                {
                    targets: '#content',
                    opacity: [0, 1],
                    translateY: ['100vh', 0]
                },
                '-=500'
            )
    }

    const onCloseModal = (event) => {
        if (event && event.currentTarget === event.target && onClose) {
            onClose()
        }
    }

    if (isVisible) {
        return (
            <div
                id="modal"
                className="modal flex fixed top-0 left-0 bottom-0 right-0 z-50 bg-dark-400/80 overflow-auto pt-20 cursor-pointer"
                onMouseDown={onCloseModal}>
                <div
                    id="content"
                    className="py-10 px-6 bg-dark-500 mt-auto mx-auto rounded-tl-2xl rounded-tr-2xl w-full cursor-default relative">
                    {showDetails && (
                        <div className={`flex justify-between items-start`}>
                            {title && (
                                <h2 className="font-bold text-xl text-white">
                                    {title}
                                </h2>
                            )}
                            {showClose && (
                                <XMarkIcon
                                    className={`${title
                                            ? 'ml-4 mt-0.5 w-6 h-6 shrink-0 self-start'
                                            : 'top-10 right-6 absolute'
                                        } cursor-pointer text-text`}
                                    onClick={onCloseModal}
                                />
                            )}
                        </div>
                    )}

                    {description && <p className="mb-6">{description}</p>}

                    {children}
                </div>
            </div>
        )
    }
}
