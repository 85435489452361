import React, { Fragment, useContext, useEffect, useState } from 'react'
import { UserContext } from 'context/UserContext'
import { ChaptersAPI, KilometersAPI, MembersAPI } from 'api'

import Header from './Header'
import Details from './Details'
import Members from './Members'
import HomeKilometers from './Kilometers'
import { USER_ROLES } from 'constants'
import Chapters from './Chapters'

export default function Home() {
    const { state: userState, setState: setUserState } = useContext(UserContext)
    const [kilometersDetails, setKilometersDetails] = useState({})
    const [kilometers, setKilometers] = useState([])
    const [members, setMembers] = useState([])
    const [chapters, setChapters] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        onGetInitialValues()
    }, [])

    const onGetInitialValues = async () => {
        await onGetMemebrs()
        await onGetMyProfile()
        await onGetMyKilometers()

        if (userState.user?.role === USER_ROLES.TLATOANI) {
            await onGetChapters()
        }

        setLoading(false)
    }

    const onGetChapters = async () => {
        const { data } = await ChaptersAPI.get()
        setChapters(data.chapters)
    }

    const onGetMemebrs = async () => {
        const { data } = await MembersAPI.get({
            showMe: false
        })

        setMembers(data.users)
    }

    const onGetMyProfile = async () => {
        const { data } = await MembersAPI.getOne(userState.user._id)

        setUserState((prevState) => ({
            ...prevState,
            user: {
                ...prevState.user,
                name: data.user.name
            }
        }))

        setKilometersDetails(data.kilometers)
    }

    const onGetMyKilometers = async () => {
        const { data } = await KilometersAPI.get(userState.user._id, {
            filter: 'month'
        })

        setKilometers(data.kilometers)
    }

    return (
        <div className="flex flex-col gap-10">
            <Header />
            <Details kilometersDetails={kilometersDetails} loading={loading} />

            {userState.user.role === USER_ROLES.TLATOANI ? (
                <Chapters chapters={chapters} loading={loading} />
            ) : (
                <Members members={members} loading={loading} />
            )}

            <HomeKilometers kilometers={kilometers} loading={loading} />
        </div>
    )
}
