import React from 'react'
import BaseModal from './index'
import SandClockSVG from 'assets/sand_clock.svg'

const TokenValidationModal = ({ isVisible, onClose, showDetails }) => {
    return (
        <BaseModal
            isVisible={isVisible}
            onClose={onClose}
            showDetails={showDetails}>
            <div className="text-center flex flex-col">
                <img src={SandClockSVG} className="mb-4 w-12 mx-auto" />

                <h2 className="font-bold text-xl text-white mb-2">
                    Invitación inválida
                </h2>
                <div>
                    Parece que la invitación que recibiste ha expirado o es
                    inválida. Te recuerdo que dispones de un plazo de 24 horas
                    para hacer uso de ella antes de que caduque. Por favor,
                    vuelve a solicitar una nueva.
                </div>
            </div>
        </BaseModal>
    )
}

export default TokenValidationModal
