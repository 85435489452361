import React, { useState, useEffect } from 'react'
import { emitter } from 'utils'
import anime from 'animejs'

import { InformationCircleIcon, XMarkIcon } from '@heroicons/react/24/outline'

export default function NotificationModal() {
    const [options, setOptions] = useState({})
    const [visible, setVisible] = useState(false)
    const [countdown, setCountDown] = useState(false)

    useEffect(() => {
        emitter.on('onOpenNotification', onOpenNotification)

        return () => {
            emitter.off('onOpenNotification', onOpenNotification)
        }
    }, [])

    useEffect(() => {
        if (visible === true) {
            fadeInAnimation()

            let countdown = setTimeout(() => {
                onCloseNotification()
            }, 5000)

            setCountDown(countdown)
        } else {
            clearTimeout(countdown)
        }
    }, [visible])

    const onOpenNotification = (options) => {
        setOptions(options)
        setVisible(true)
    }

    const fadeInAnimation = () => {
        let container = document.getElementById('notification')

        anime
            .timeline({
                easing: 'easeInCubic',
                targets: container
            })
            .add({
                opacity: [0, 1],
                duration: 500
            })
            .add(
                {
                    easing: 'easeOutElastic(1, .6)',
                    translateY: [150, 0],
                    duration: 800
                },
                '-=250'
            )
    }

    const getNotificationDetails = () => {
        if (options.type === 'success') {
            return {
                text: 'text-green',
                bg: 'bg-green/20'
            }
        }
    }

    const onCloseNotification = () => {
        setVisible(false)
    }

    if (visible) {
        return (
            <div
                id="notification"
                className={`fixed bottom-[114px] right-0 left-0 z-10 w-full max-w-[450px] px-6 mx-auto`}
                onClick={onCloseNotification}>
                <div className={`flex p-4 rounded-lg bg-dark-400`}>
                    <div
                        className={`h-10 w-10 shrink-0 rounded-full mr-4 flex ${
                            getNotificationDetails().bg
                        }`}>
                        <InformationCircleIcon
                            className={`w-6 m-auto ${
                                getNotificationDetails().text
                            }`}
                        />
                    </div>
                    <div className="mr-auto">
                        <div className="text-sm font-semibold text-white">
                            {options.title}
                        </div>
                        <div
                            className="text-sm"
                            dangerouslySetInnerHTML={{
                                __html: options.message
                            }}></div>
                    </div>
                    <XMarkIcon className="w-6 text-text" />
                </div>
            </div>
        )
    }
}
