import React from 'react'

export default function SkeletonItem() {
    return (
        <div className="bg-dark-500/35 rounded-lg p-4">
            <div className="bg-dark-400/35 h-3 w-1/2 rounded-lg mb-3"></div>
            <div className="bg-dark-400/35 h-3 w-full rounded-lg"></div>
        </div>
    )
}
